import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "config/store"
import { Castle, Bridge, Playground } from "./components"
import * as duck from "./duck"
import * as purchaseDuck from "features/purchase/duck"
import * as warriorsSelectorDuck from "features/warriorsSelector/duck"
import * as colorSelectorDuck from "features/colorSelector/duck"
import * as lobbyDuck from "features/lobby/duck"
import { BattlefieldWrap, BridgesWrap } from "./elements"

export const Battlefield = () => {
    const dispatch = useAppDispatch()
    // const isPurchaseSuccessful = useSelector(purchaseDuck.selectors.selectIsPurchaseSuccessful)
    // const isPurchaseActive = useSelector(purchaseDuck.selectors.selectIsPurchaseActive)
    const selectedWarrior = useSelector(warriorsSelectorDuck.selectors.selectSelectedWarrior)
    const ourWarriors = useSelector(duck.selectors.selectOurWarriors)
    const theirWarriors = useSelector(duck.selectors.selectTheirWarriors)
    const towerHealths = useSelector(duck.selectors.selectTowerHealths)
    const colorObj = useSelector(colorSelectorDuck.selectors.selectColorObj)
    const opponentColorObj = useSelector(lobbyDuck.selectors.selectOpponentColorObj)

    // useEffect(() => {
    //     if (!isPurchaseActive && selectedWarrior) {
    //         if (isPurchaseSuccessful) {
    //             dispatch(duck.actions.addWarrior(selectedWarrior.type))
    //         }

    //         dispatch(warriorsSelectorDuck.actions.unselectWarrior())
    //     }
    // }, [dispatch, isPurchaseActive, isPurchaseSuccessful, selectedWarrior])
    useEffect(() => {
        if (selectedWarrior) {
            dispatch(purchaseDuck.actions.decreaseCoinsAmount(selectedWarrior.price))
            dispatch(duck.actions.addWarrior(selectedWarrior.type))
            dispatch(warriorsSelectorDuck.actions.unselectWarrior())
        }
    }, [dispatch, selectedWarrior])

    return (
        <BattlefieldWrap>
            <BridgesWrap>
                <Bridge side="right" />
                <Bridge side="left" />
            </BridgesWrap>
            <Castle side="left" health={towerHealths.our} />
            <Castle side="right" health={towerHealths.their} />
            <Playground
                ourWarriors={ourWarriors}
                theirWarriors={theirWarriors}
                colorObj={colorObj}
                opponentColorObj={opponentColorObj}
            />
        </BattlefieldWrap>
    )
}
