import { useAppDispatch } from "config/store"
import * as appDuck from "features/app/duck"
import { Menu } from "shared/ui/components/menu"

const BUTTONS_TEXTS = ["Играть", "Игра с друзьями", "Мои воины"]
export const BUTTONS_KEYS = ["play", "playWithFriends", "warriors"]

export const MainMenu = () => {
    const dispatch = useAppDispatch()

    const onButtonClickHandler = (e) => {
        dispatch(appDuck.actions.togglePage(e.target.id))
    }

    const mapButtonsProps = () =>
        BUTTONS_TEXTS.map((text, i) => ({
            text,
            width: 300,
            id: BUTTONS_KEYS[i],
            disabled: i === 1,
            onClick: onButtonClickHandler,
        }))

    return <Menu buttons={mapButtonsProps()} />
}
