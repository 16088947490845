import styled from "styled-components"
import { adaptPx } from "utils/style-utils"

export const WarriorsSelectorWrap = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    height: ${adaptPx(120)};
    width: 100%;
    background: ${(props) => props.theme.warriorSelector.bg};
    display: flex;
    align-items: center;
`

export const CoinsWrap = styled.div`
    position: absolute;
    right: ${adaptPx(20)};
    top: 0;
    height: 100%;
    width: ${adaptPx(250)};
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: ${adaptPx(48)};
`

export const CoinsAmount = styled.div`
    font-weight: 600;
    text-align: right;
`
