import styled from "styled-components"
import { adaptPx } from "utils/style-utils"

export const MenuPageWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    top: 0;
    left: 0;
`

export const Title = styled.div`
    font-weight: 900;
    font-size: ${adaptPx(64)};
    line-height: ${adaptPx(87)};
    color: #0a5c8a;
`
