import { useSelector } from "react-redux"
import { useAppDispatch } from "config/store"
import * as duck from "./duck"
import { Button } from "shared/ui/components/button"
import { PurchaseWrap, ExampleWrap, ButtonWrap } from "./elements"

export const Purchase = () => {
    const dispatch = useAppDispatch()

    const isPurchaseActive = useSelector(duck.selectors.selectIsPurchaseActive)
    const purchaseType = useSelector(duck.selectors.selectPurchaseType)

    const buttonClickHandler = () => {
        setTimeout(() => {
            dispatch(duck.actions.closePurchase(true))
        }, 1000)
    }

    if (!isPurchaseActive) return null
    return (
        <PurchaseWrap>
            <ExampleWrap>
                {/* TODO: не забыть проапдейтить */}
                <h3>***Here will some buying logic for {purchaseType}...***</h3>
            </ExampleWrap>
            <ButtonWrap>
                <Button text="OK" className="exampleButton" onClick={buttonClickHandler} />
            </ButtonWrap>
        </PurchaseWrap>
    )
}
