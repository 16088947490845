import styled from "styled-components"
import { adaptPx } from "utils/style-utils"
import { COLOR_OBJECTS } from "shared/constants/colorObjects"
import bg from "./images/color_selector_bg.svg"
import block from "./images/blocked_shield.svg"
import hover from "./images/blocked_shield_hover.svg"

export const ColorSelectorWrap = styled.div`
    width: ${adaptPx(605)};
    height: ${adaptPx(425)};
    min-height: ${adaptPx(425)};
    background-color: #ffffff;
    background-image: url(${bg});
    border-radius: ${adaptPx(20)};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`

export const ColorShield = styled.div<{ $index: number }>`
    width: ${adaptPx(99)};
    height: ${adaptPx(131)};
    background: url(${(props) => COLOR_OBJECTS[props.$index].imageURL}) center/contain no-repeat;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;

    &.blocked {
        &:hover {
            .hoverShield {
                opacity: 1;
            }
        }
    }
    &:not(.blocked):hover {
        transform: scale(1.1);
    }
    .hoverShield {
        width: ${adaptPx(99)};
        height: ${adaptPx(131)};
        position: absolute;
        transition: opacity 0.2s;
        background: url(${hover});
        opacity: 0;
        pointer-events: none;
    }
`

export const ShieldsRaw = styled.div`
    display: flex;
    justify-content: space-evenly;
`

export const BlockIcon = styled.div`
    pointer-events: none;
    background: url(${block}) center/contain no-repeat;
    width: ${adaptPx(63)};
    height: ${adaptPx(63)};
`
