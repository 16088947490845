import { useState } from "react"
import { PreGamePageWrap, Title } from "./elements"
import { ColorSelector } from "features/colorSelector"
import { Lobby } from "features/lobby"
import { Header } from "features/shared/header"

const TITLES = {
    colorSelector: "Выбери цвет башни",
    lobby: "Участники",
}

export const PreGamePage = () => {
    const [currentView, setCurrentView] = useState("lobby")

    const toggleView = () => {
        setCurrentView(currentView === "colorSelector" ? "lobby" : "colorSelector")
    }

    return (
        <PreGamePageWrap>
            <Header stars pageId="menu" />
            <Title>{TITLES[currentView]}</Title>
            {currentView === "colorSelector" ? (
                <ColorSelector toggleView={toggleView} />
            ) : (
                <Lobby toggleView={toggleView} />
            )}
        </PreGamePageWrap>
    )
}
