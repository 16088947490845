import styled, { css } from "styled-components"
import { adaptPx } from "utils/style-utils"

export const BridgeWrap = styled.div<{ $side: "left" | "right" }>`
    width: 50%;
    height: ${adaptPx(148)};
    position: absolute;
    bottom: 0;

    ${(props) =>
        props.$side === "left"
            ? css`
                  left: ${adaptPx(9)};
              `
            : css`
                  right: ${adaptPx(9)};
              `}

    .bridge-image {
        position: absolute;
        width: auto;
        height: 100%;
        top: 0;

        ${(props) =>
            props.$side === "left"
                ? css`
                      right: 0;

                      [fill="#F487C8"] {
                          fill: ${(props) => props.theme.leftCastle.color1};
                      }
                      [fill="#E85DB0"] {
                          fill: ${(props) => props.theme.leftCastle.color2};
                      }
                      [fill="#AF2C7A"] {
                          fill: ${(props) => props.theme.leftCastle.color4};
                      }
                  `
                : css`
                      left: 0;

                      transform: scale(-1, 1);
                      [fill="#F487C8"] {
                          fill: ${(props) => props.theme.rightCastle.color1};
                      }
                      [fill="#E85DB0"] {
                          fill: ${(props) => props.theme.rightCastle.color2};
                      }
                      [fill="#AF2C7A"] {
                          fill: ${(props) => props.theme.rightCastle.color4};
                      }
                  `}
    }
`
