export default function keyMirror<T extends string, P extends string>(
    types: Record<T, null>,
    prefix: P
): { [key in T]: `${P}/${key}` } {
    return Object.keys(types).reduce(
        (p, c: T) => ({
            ...p,
            [c]: `${prefix}/${c}`,
        }),
        {} as { [key in T]: `${P}/${key}` }
    )
}
