import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { socket } from "utils/socket"
import { COLOR_OBJECTS } from "shared/constants/colorObjects"
import keyMirror from "utils/keymirror"
import type { ColorObj } from "features/colorSelector/duck/types"
import type { Opponent } from "./types"

const defaultColor = COLOR_OBJECTS[COLOR_OBJECTS.length - 1]

const FEATURE_NAME = "lobby"

const actionTypes = keyMirror(
    {
        READY_TO_PLAY: null,
        SET_GAME_LINK: null,
        SET_OPPONENT_READY: null,
        SET_OPPONENT_COLOR: null,
    },
    FEATURE_NAME
)

const initialState = {
    readyToPlay: false,
    gameLink: "",
    opponent: null as Opponent | null,
}

const setReadyToPlay = createAsyncThunk(
    actionTypes.READY_TO_PLAY,
    (isReady: boolean) => {
        if (isReady) {
            socket.emitEvent("ready")
        }
        return isReady
    }
)
const setGameLink = createAsyncThunk(
    actionTypes.SET_GAME_LINK,
    (link: string) => link
)
const setOpponentReady = createAsyncThunk(actionTypes.SET_OPPONENT_READY, () => {})
const setOpponentColor = createAsyncThunk(
    actionTypes.SET_OPPONENT_COLOR,
    (colorObj: ColorObj) => colorObj
)

const lobbySlice = createSlice({
    name: FEATURE_NAME,
    initialState,
    reducers: {
        addOpponent: (state, action: PayloadAction<Opponent["name"]>) => {
            state.opponent = {
                name: action.payload,
                colorObj: defaultColor,
                isReady: false,
            }
        },
        removeOpponent: (state) => {
            state.opponent = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setReadyToPlay.fulfilled, (state, action) => {
                state.readyToPlay = action.payload
            })
            .addCase(setGameLink.fulfilled, (state, action) => {
                state.gameLink = action.payload
            })
            .addCase(setOpponentReady.fulfilled, (state) => {
                state.opponent.isReady = true
            })
            .addCase(setOpponentColor.fulfilled, (state, action) => {
                state.opponent.colorObj = action.payload
            })
    },
    selectors: {
        selectReadyToPlay: (state) => state.readyToPlay,
        selectGameLink: (state) => state.gameLink,
        selectOpponent: (state) => state.opponent,
        selectOpponentColor: (state) => state.opponent?.colorObj.name || "default",
        selectOpponentColorObj: (state) => state.opponent?.colorObj || defaultColor,
    },
})

export const actions = { ...lobbySlice.actions, setReadyToPlay, setGameLink, setOpponentReady, setOpponentColor }
export const selectors = lobbySlice.selectors
export default lobbySlice.reducer
