import styled from "styled-components"
import { adaptPx } from "utils/style-utils"
import cloud from "shared/images/cloud.svg"

export const PurchaseWrap = styled.div`
    position: absolute;
    height: ${adaptPx(280)};
    width: ${adaptPx(716)};
    top:  ${adaptPx(-1)};
    left: 50%;
    transform: translateX(-50%);
    background: url(${cloud}) center/contain no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: ${adaptPx(70)} 0;
`

export const ExampleWrap = styled.div`
    text-align: center;
    font-size: ${adaptPx(16)};
`

export const ButtonWrap = styled.div`
    text-align: center;

    .exampleButton {
        padding: ${adaptPx(18)} 0;
        width: ${adaptPx(48)};
        height: ${adaptPx(12)};
        line-height: ${adaptPx(12)};
        border-radius: ${adaptPx(6)};
    }
    .error {
        background: #f05a69;
    }
    .correct {
        background: #8ebf80;
    }
`
