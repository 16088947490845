import { CastleWrap, Shield, Health, HealthWrap, Heart, HealthMainWrap, Substrate } from "./elements"
import { ReactComponent as CastleImage } from "../../images/castle.svg"

export const Castle = ({ side, health }: { side: "left" | "right", health: number }) => {
    return (
        <CastleWrap $side={side}>
            <CastleImage className="castle-image" />
            <Shield $side={side} />
            <HealthMainWrap $side={side}>
                <HealthWrap $side={side} $health={health}>
                    <Health $side={side} />
                    <Heart />
                </HealthWrap>
            </HealthMainWrap>
            <Substrate $side={side}>{health}</Substrate>
        </CastleWrap>
    )
}
