import { css, type ExecutionContext } from "styled-components"

export const media = {
    laptop: (args) =>
        css`
            @media (min-width: 1024px) {
                ${css(args)}
            }
        `,
    tablet: (args) =>
        css`
            @media (max-width: 1023px) {
                ${css(args)}
            }
        `,
    mobileM: (args) =>
        css`
            @media (max-width: 767px) {
                ${css(args)}
            }
        `,
    mobileS: (args) =>
        css`
            @media (max-width: 567px) {
                ${css(args)}
            }
        `,
    mobileXS: (args) =>
        css`
            @media (max-width: 320px) {
                ${css(args)}
            }
        `,
}

export const calcDesignPxSize = () => {
    const designWidth = 1024
    const designHeight = 560
    return window.innerWidth / window.innerHeight < designWidth / designHeight
        ? window.innerWidth / designWidth
        : window.innerHeight / designHeight
}

export const adaptPx = (px: number): ReturnType<typeof css> =>
    ((px: number) => (props: ExecutionContext) =>
        css`${props.theme.designPxSize * px}px`)
            .call(this, px)
