import { WarriorsPageWrap, Title } from "./elements"
import { WarriorsMenu } from "features/warriorsMenu"
import { Header } from "features/shared/header"

export const WarriorsPage = () => {
    return (
        <WarriorsPageWrap>
            <Header stars pageId="menu" />
            <Title>Мои воины</Title>
            <WarriorsMenu />
        </WarriorsPageWrap>
    )
}
