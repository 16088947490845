import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit"
import {
    STATS_HEALTH,
    START_WALKING_ANIMATION_DURATION_MS,
    STOP_WALKING_ANIMATION_DURATION_MS,
    ATTACK_COOLDOWN_MS,
    WEAPON_FLIGHT_DURATION_MS,
} from "shared/constants/gameplayConstants"
import { PLAYGROUND_POINTS_AMOUNT } from "shared/constants/playgroundPoints"
import keyMirror from "utils/keymirror"
import { socket } from "utils/socket"
import warriors from "static/warriors.json"
import { canGo, findEnemy, checkIfShouldAttack } from "./helpers"
import type { AppState, AppDispatch } from "config/types"
import type { WarriorAtBattlefield, OurWarrior, TheirWarrior } from "./types"

const FEATURE_NAME = "battlefield"

const actionTypes = keyMirror(
    {
        ADD_WARRIOR: null,
        MOVE_WARRIOR: null,
        MOVE_OPPONENT_WARRIOR: null,
        ATTACK: null,
        OPPONENT_ATTACK: null,
    },
    FEATURE_NAME
)

const initialState = {
    ourWarriors: {} as Record<WarriorAtBattlefield["key"], OurWarrior>,
    theirWarriors: {} as Record<WarriorAtBattlefield["key"], TheirWarrior>,
    ourWarriorsTotalAmount: 0,
    towerHealths: {
        our: 100,
        their: 100,
    },
    isGameOver: false,
    /** do we have advantage in controversual situations */
    isWalkAdvantage: false,
}

const addWarrior = createAsyncThunk<{ warrior: OurWarrior }, WarriorAtBattlefield["type"], { state: AppState }>(
    actionTypes.ADD_WARRIOR,
    async (type, thunkAPI) => {
        return new Promise((resolve) => {
            const { ourWarriors, theirWarriors, ourWarriorsTotalAmount, isGameOver } = thunkAPI.getState()[FEATURE_NAME]
            const key = ourWarriorsTotalAmount
            socket.emitEvent("add-warrior", type, key)
            const warriorTemplate = warriors.find((w) => w.type === type)
            const stats = { ...warriorTemplate.stats }
            const warrior: OurWarrior = {
                ...warriorTemplate,
                stats,
                key,
                position: 0,
                inBattle: false,
                walkStatus: null,
                opposite: false,
                moveStartMoments: {},
                shouldMove: false,
                shouldAttack: false,
                enemyKey: undefined,
                currentHealth: stats.health * STATS_HEALTH,
            }

            if (!isGameOver) {
                const enemyKey = findEnemy(warrior, theirWarriors) as number | undefined
                if (enemyKey === undefined) {
                    warrior.shouldMove = canGo(warrior, false, ourWarriors, theirWarriors)
                } else {
                    // console.log("setting for our new warrior", warrior.type, warrior.key, "enemy", theirWarriors[enemyKey].type, enemyKey, theirWarriors[enemyKey].walkStatus)
                    warrior.enemyKey = enemyKey
                    warrior.shouldAttack = true
                }
            }

            resolve({ warrior })
        })
    }
)

/** Move warrior for 1 playground point and apply animations */
const moveWarrior = createAsyncThunk<
    { key: OurWarrior["key"]; enemyKey: OurWarrior["enemyKey"]; withStop: boolean },
    WarriorAtBattlefield["key"],
    { state: AppState; dispatch: AppDispatch }
>(actionTypes.MOVE_WARRIOR, (key, thunkAPI) => {
    return new Promise((resolve) => {
        const warrior = thunkAPI.getState()[FEATURE_NAME].ourWarriors[key]
        const { pace } = warrior.stats

        const withStartAnimation = warrior.walkStatus !== "walk"
        if (withStartAnimation) {
            thunkAPI.dispatch(actions.startWalking({ key, opposite: false }))
            setTimeout(() => {
                // In next adjacent moves this animation will aready be applied, so no need to do this
                thunkAPI.dispatch(actions.walk({ key, opposite: false }))
            }, START_WALKING_ANIMATION_DURATION_MS)
        }

        // getting warrior again here to use actual walkStatus in canGo calculations
        const { ourWarriors, theirWarriors } = thunkAPI.getState()[FEATURE_NAME]
        let withStop = !canGo(ourWarriors[key], true, ourWarriors, theirWarriors)
        socket.emitEvent("move-warrior", key, warrior.position, withStop)

        // Dispatch fulfilled action after transition for 1 point ended
        setTimeout(() => {
            // We should re-check, because the new info could arrived
            const { ourWarriors, theirWarriors, isWalkAdvantage } = thunkAPI.getState()[FEATURE_NAME]
            const warrior = ourWarriors[key]
            let enemyKey: OurWarrior["enemyKey"]
            if (!warrior?.isKilled) {
                // console.log("re-check canGo before resolving moveWarrior", warrior?.type, warrior?.key, "to position", warrior?.position)
                // this will find only enemies which can instantly be attacked after getting to our warrior.position
                enemyKey = findEnemy(warrior, theirWarriors)
                withStop =
                    enemyKey !== undefined ||
                    !canGo(warrior, false, ourWarriors, theirWarriors, !withStop, isWalkAdvantage)
            }
            resolve({ key, enemyKey, withStop })
        }, pace)

        if (withStop) {
            setTimeout(() => {
                thunkAPI.dispatch(actions.stopWalking({ key, opposite: false }))
            }, pace - STOP_WALKING_ANIMATION_DURATION_MS)
        }
    })
})

const moveOpponentWarrior = createAsyncThunk<
    { warrior: WarriorAtBattlefield; withStop: boolean },
    Pick<WarriorAtBattlefield, "key" | "position"> & { withStop: boolean },
    { state: AppState; dispatch: AppDispatch }
>(actionTypes.MOVE_OPPONENT_WARRIOR, ({ key, withStop }, thunkAPI) => {
    return new Promise((resolve) => {
        const warrior = thunkAPI.getState()[FEATURE_NAME].theirWarriors[key]
        const { pace } = warrior.stats
        const withStartAnimation = warrior.walkStatus !== "walk"

        // Dispatch fulfilled action after transition for 1 point ended
        setTimeout(() => {
            resolve({ warrior, withStop })
        }, pace)

        if (withStartAnimation) {
            thunkAPI.dispatch(actions.startWalking({ key, opposite: true }))
            setTimeout(() => {
                // In next adjacent moves this animation will stay
                thunkAPI.dispatch(actions.walk({ key, opposite: true }))
            }, START_WALKING_ANIMATION_DURATION_MS)
        }

        if (withStop) {
            setTimeout(() => {
                thunkAPI.dispatch(actions.stopWalking({ key, opposite: true }))
            }, pace - STOP_WALKING_ANIMATION_DURATION_MS)
        }
    })
})

const attack = createAsyncThunk<
    { key: OurWarrior["key"]; newEnemyKey: OurWarrior["enemyKey"] },
    OurWarrior["key"],
    { state: AppState; dispatch: AppDispatch }
>(actionTypes.ATTACK, (key, thunkAPI) => {
    return new Promise((resolve) => {
        const { ourWarriors, theirWarriors } = thunkAPI.getState()[FEATURE_NAME]
        const warrior = ourWarriors[key]
        const weaponFlightDuration =
            warrior.stats.distance &&
            (warrior.enemyKey === "tower" || theirWarriors[warrior.enemyKey].position !== warrior.position)
                ? WEAPON_FLIGHT_DURATION_MS
                : 0
        setTimeout(() => {
            const { ourWarriors, theirWarriors, towerHealths } = thunkAPI.getState()[FEATURE_NAME]
            const warrior = ourWarriors[key]

            if (!warrior?.isKilled) {
                if (warrior.enemyKey === "tower") {
                    if (towerHealths.their > 0) {
                        if (weaponFlightDuration) {
                            thunkAPI.dispatch(actions.startShooting({ key: warrior.key, opposite: false }))
                        }
                        // console.log("our warrior", warrior.type, warrior.key, "is attacking tower from position", warrior.position)
                        socket.emitEvent("attack-tower", warrior.key, warrior.stats.attack)
                    } else {
                        // console.log("their tower should be attacked by", warrior.type, warrior.key, "but was destroyed during a cooldown")
                    }
                } else {
                    if (!theirWarriors[warrior.enemyKey]?.isKilled) {
                        if (weaponFlightDuration) {
                            thunkAPI.dispatch(actions.startShooting({ key: warrior.key, opposite: false }))
                        }
                        // console.log("warrior", warrior.type, warrior.key, "standing at position", warrior.position, "is attacking", theirWarriors[warrior.enemyKey].type, warrior.enemyKey, theirWarriors[warrior.enemyKey].walkStatus ? "moving to" : "standing at", "position", theirWarriors[warrior.enemyKey].position)
                        socket.emitEvent("attack-warrior", warrior.key, warrior.enemyKey, warrior.stats.attack)
                    } else {
                        // console.log("their warrior", warrior.enemyKey, "should be attacked by", warrior.type, warrior.key, "but was killed during a cooldown")
                    }
                }
                // }
            } else {
                // console.log("warrior", key, "wanted to attack but was killed during a cooldown")
            }
            setTimeout(() => {
                const { ourWarriors, theirWarriors, isGameOver } = thunkAPI.getState()[FEATURE_NAME]
                const warrior = ourWarriors[key]
                /** next enemy, after current attack */
                let newEnemyKey: OurWarrior["enemyKey"]
                if (!warrior?.isKilled && !isGameOver) {
                    newEnemyKey = findEnemy(warrior, theirWarriors)
                }

                resolve({ key, newEnemyKey })
            }, weaponFlightDuration);
        }, ATTACK_COOLDOWN_MS - weaponFlightDuration)
    })
})

const opponentAttack = createAsyncThunk<
    TheirWarrior["key"],
    {
        key: TheirWarrior["key"]
        enemyKey: OurWarrior["key"] | "tower"
        amount: WarriorAtBattlefield["stats"]["attack"]
    },
    { state: AppState; dispatch: AppDispatch }
>(actionTypes.OPPONENT_ATTACK, ({ key, enemyKey, amount }, thunkAPI) => {
    return new Promise((resolve) => {
        const { ourWarriors, theirWarriors, towerHealths } = thunkAPI.getState()[FEATURE_NAME]
        const warrior = theirWarriors[key]
        const weaponFlightDuration =
            warrior.stats.distance && (enemyKey === "tower" || ourWarriors[enemyKey].position !== warrior.position)
                ? WEAPON_FLIGHT_DURATION_MS
                : 0

        if (!warrior?.isKilled) {
            if (enemyKey === "tower") {
                if (towerHealths.our > 0) {
                    if (weaponFlightDuration) {
                        thunkAPI.dispatch(actions.startShooting({ key: warrior.key, opposite: true, enemyKey: "tower" }))
                    }
                    // console.log("their warrior", warrior.type, warrior.key, "is attacking tower from position", warrior.position)
                } else {
                    // console.log("our tower should be attacked by", warrior.type, warrior.key, "but was destroyed during a cooldown")
                }
            } else {
                if (!ourWarriors[enemyKey]?.isKilled) {
                    if (weaponFlightDuration) {
                        thunkAPI.dispatch(actions.startShooting({ key: warrior.key, opposite: true, enemyKey }))
                    }
                    // console.log("warrior", warrior.type, warrior.key, "standing at position", warrior.position, "is attacking", ourWarriors[enemyKey].type, warrior.enemyKey, ourWarriors[enemyKey].walkStatus ? "moving to" : "standing at", "position", ourWarriors[enemyKey].position)
                } else {
                    // console.log("our warrior", enemyKey, "should be attacked by", warrior.type, warrior.key, "but was killed during a cooldown")
                }
            }
            // }
        } else {
            // console.log("warrior", key, "wanted to attack but was killed during a cooldown")
        }
        setTimeout(() => {
            if (enemyKey === "tower") {
                thunkAPI.dispatch(actions.dropTowerLife(amount))
            } else {
                thunkAPI.dispatch(actions.dropWarriorLife({ warriorKey: enemyKey, amount }))
            }
            resolve(key)
        }, weaponFlightDuration)
    })
})

const battlefieldSlice = createSlice({
    name: FEATURE_NAME,
    initialState,
    reducers: {
        setAdvantage: (state, action: PayloadAction<boolean>) => {
            state.isWalkAdvantage = action.payload
        },
        addOpponentWarrior: (state, action: PayloadAction<Pick<WarriorAtBattlefield, "type" | "key">>) => {
            const { type, key } = action.payload
            const warriorTemplate = warriors.find((w) => w.type === type)
            const stats = { ...warriorTemplate.stats }
            const warrior: TheirWarrior = {
                ...warriorTemplate,
                stats,
                key,
                position: PLAYGROUND_POINTS_AMOUNT - 1,
                inBattle: false,
                walkStatus: null,
                opposite: true,
                moveStartMoments: {},
                willStop: false,
                currentHealth: stats.health * STATS_HEALTH,
            }
            state.theirWarriors[warrior.key] = warrior

            if (!state.isGameOver) {
                // replacing enemyKey of our warriors attacking the tower
                for (const ourWarrior of Object.values(state.ourWarriors)) {
                    if (!ourWarrior.isKilled && !ourWarrior.walkStatus && checkIfShouldAttack(ourWarrior, warrior)) {
                        // console.log("setting their warrior", warrior.type, warrior.key, ", who was just added, as enemy for our warrior", ourWarrior.type, ourWarrior.key)
                        ourWarrior.enemyKey = key

                        // this shouldn't ever happen, just in case
                        if (!ourWarrior.inBattle) {
                            ourWarrior.shouldAttack = true
                        }
                    }
                }
            }
        },
        startWalking: (state, action: PayloadAction<Pick<WarriorAtBattlefield, "key" | "opposite">>) => {
            if (action.payload.opposite) {
                // console.log("Start walking to position", state.theirWarriors[action.payload.key].position, "their", state.theirWarriors[action.payload.key].type, action.payload.key)
                state.theirWarriors[action.payload.key].walkStatus = "start"} else {
                // console.log("Start walking to position", state.ourWarriors[action.payload.key].position, "our", state.ourWarriors[action.payload.key].type, action.payload.key)
                state.ourWarriors[action.payload.key].walkStatus = "start"}
        },
        walk: (state, action: PayloadAction<Pick<WarriorAtBattlefield, "key" | "opposite">>) => {
            const warrior = action.payload.opposite
                ? state.theirWarriors[action.payload.key]
                : state.ourWarriors[action.payload.key]
            if (!warrior?.isKilled) {
                // console.log("Walk to position", warrior.position, action.payload.opposite ? "their": "our", warrior.type, action.payload.key)
                warrior.walkStatus = "walk"
            }
        },
        stopWalking: (state, action: PayloadAction<Pick<WarriorAtBattlefield, "key" | "opposite">>) => {
            const warrior = action.payload.opposite
                ? state.theirWarriors[action.payload.key]
                : state.ourWarriors[action.payload.key]
            if (!warrior?.isKilled) {
                // console.log("Stop walking to position", warrior.position, action.payload.opposite ? "their": "our", warrior.type, action.payload.key)
                warrior.walkStatus = "stop"
            }
        },
        startShooting: (state, action: PayloadAction<Pick<WarriorAtBattlefield, "key" | "opposite"> & { enemyKey?: WarriorAtBattlefield["enemyKey"] }>) => {
            const warrior = action.payload.opposite
                ? state.theirWarriors[action.payload.key]
                : state.ourWarriors[action.payload.key]
            if (!warrior?.isKilled) {
                // console.log("Start shooting", action.payload.opposite ? "their": "our", warrior.type, action.payload.key, Date.now())
                warrior.isShooting = true
                if (action.payload.opposite) {
                    warrior.enemyKey = action.payload.enemyKey
                }
            }
        },
        breakOpponentWarriorMove: (state, action: PayloadAction<WarriorAtBattlefield["key"]>) => {
            const theirWarrior = state.theirWarriors[action.payload]
            if (theirWarrior) {
                theirWarrior.walkStatus = null
            }

            if (!state.isGameOver) {
                // checking if some our warrior should attack this warrior
                for (const ourWarrior of Object.values(state.ourWarriors)) {
                    if (!ourWarrior.isKilled && !ourWarrior.walkStatus && ourWarrior.enemyKey === undefined) {
                        if (checkIfShouldAttack(ourWarrior, theirWarrior)) {
                            // console.log("setting their warrior", theirWarrior.type, theirWarrior.key, "who has urgently stopped at position", theirWarrior.position, "as enemy for our warrior", ourWarrior.type, ourWarrior.key, "standing at position", ourWarrior.position, !ourWarrior.inBattle && "and force them to attack immediately")
                            ourWarrior.enemyKey = theirWarrior.key
                            if (!ourWarrior.inBattle) {
                                ourWarrior.shouldAttack = true
                            }
                        } else {
                            // checking if warrior is standing and not fighting
                            // console.log("checking if our standing still and not fighting warrior", ourWarrior.type, ourWarrior.key, "at position", ourWarrior.position, "should actually go. Trigger is breakOpponentWarriorMove of", theirWarrior.type, theirWarrior.key, "at position", theirWarrior.position)
                            ourWarrior.shouldMove = canGo(
                                ourWarrior,
                                false,
                                state.ourWarriors,
                                state.theirWarriors
                            )
                            if (!ourWarrior.shouldMove) {
                                // console.log("Previously checked warrior shouldn't go. May be they're waiting for their enemy to end move")
                            }
                        }
                    }
                }
            }
        },
        startOpponentBattle: (state, action: PayloadAction<WarriorAtBattlefield["key"]>) => {
            const warrior = state.theirWarriors[action.payload]
            warrior.inBattle = true
        },
        finishOpponentBattle: (state, action: PayloadAction<WarriorAtBattlefield["key"]>) => {
            const warrior = state.theirWarriors[action.payload]
            warrior.inBattle = false
        },
        dropWarriorLife: (
            state,
            action: PayloadAction<{ warriorKey: WarriorAtBattlefield["key"]; amount: number }>
        ) => {
            const warrior = state.ourWarriors[action.payload.warriorKey]
            let healthLeft
            if (!warrior?.isKilled) {
                healthLeft = warrior.currentHealth - action.payload.amount * STATS_HEALTH
                if (healthLeft < 0) healthLeft = 0
                socket.emitEvent("drop-warrior-life", warrior.key, healthLeft)
                warrior.currentHealth = healthLeft
            } else {
                // console.log("tried to drop life of warrior that was already killed")
            }
        },
        dropOpponentWarriorLife: (
            state,
            action: PayloadAction<{ warriorKey: WarriorAtBattlefield["key"]; amount: number }>
        ) => {
            const warrior = state.theirWarriors[action.payload.warriorKey]
            let healthLeft
            if (!warrior?.isKilled) {
                healthLeft = action.payload.amount
                warrior.currentHealth = healthLeft
            } else {
                // console.log("tried to drop life of warrior that was already killed")
            }
        },
        killWarrior: (state, action: PayloadAction<OurWarrior["key"]>) => {
            // console.log("killing our warrior", state.ourWarriors[action.payload].type, action.payload)
            const warrior = state.ourWarriors[action.payload]
            warrior.isKilled = true
            warrior.inBattle = false
            warrior.walkStatus = null
            warrior.shouldMove = false
            warrior.shouldAttack = false
        },
        killOpponentWarrior: (state, action: PayloadAction<TheirWarrior["key"]>) => {
            // console.log("killing their warrior", state.theirWarriors[action.payload].type, action.payload)
            const warrior = state.theirWarriors[action.payload]
            warrior.isKilled = true
            warrior.inBattle = false
            warrior.walkStatus = null
            warrior.willStop = true
        },
        removeWarrior: (state, action: PayloadAction<OurWarrior["key"]>) => {
            // console.log("removing our warrior", state.ourWarriors[action.payload].type, action.payload)
            delete state.ourWarriors[action.payload]
        },
        removeOpponentWarrior: (state, action: PayloadAction<TheirWarrior["key"]>) => {
            // console.log("removing their warrior", state.theirWarriors[action.payload].type, action.payload)
            delete state.theirWarriors[action.payload]
        },
        dropTowerLife: (state, action: PayloadAction<number>) => {
            const towerHealth = state.towerHealths.our
            let healthLeft
            if (towerHealth > 0) {
                healthLeft = towerHealth - action.payload * STATS_HEALTH
                if (healthLeft < 0) {
                    healthLeft = 0
                }
                if (healthLeft === 0) {
                    state.isGameOver = true
                }
                socket.emitEvent("drop-tower-life", healthLeft)
                state.towerHealths.our = healthLeft
            } else {
                // console.log("tried to drop tower life, but it's already destroyed")
            }
        },
        dropOpponentTowerLife: (state, action: PayloadAction<number>) => {
            state.towerHealths.their = action.payload
            if (action.payload === 0) {
                state.isGameOver = true
            }
        },
        resetBattle: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addWarrior.fulfilled, (state, action) => {
                state.ourWarriors[action.payload.warrior.key] = action.payload.warrior
                state.ourWarriorsTotalAmount++
            })
            .addCase(moveWarrior.pending, (state, action) => {
                const warrior = state.ourWarriors[action.meta.arg]
                // if (warrior.moveStartMoments[warrior.position]) {
                //     console.log("Warning! Replacing existing moveStartMoment for our warrior", warrior.type, warrior.key, "for position", warrior.position)
                // }
                warrior.moveStartMoments[warrior.position] = Date.now()
                // console.log("incrementing position of our warrior", warrior.type, warrior.key, "to index", warrior.position + 1, "at the moment", warrior.moveStartMoments[warrior.position], warrior.position > 0 &&warrior.moveStartMoments[warrior.position] - warrior.moveStartMoments[warrior.position - 1], "ms after previous move started", warrior.walkStatus === "walk" ? ", without stopping" : ", after stop")
                warrior.shouldMove = false
                // This actually moves warrior since it goes to css
                warrior.position = warrior.position + 1
            })
            .addCase(moveWarrior.fulfilled, (state, action) => {
                const { key, enemyKey, withStop } = action.payload
                const warrior = state.ourWarriors[key]
                if (!warrior?.isKilled) {
                    if (state.isGameOver) {
                        warrior.walkStatus = "stop"
                    } else if (enemyKey === undefined) {
                        if (withStop) {
                            if (warrior.walkStatus !== "stop") {
                                // it's an emergency stop, not predicted
                                // console.log("urgently stopping warrior", warrior.type, warrior.key, "at position", warrior.position)
                                socket.emitEvent("break-warrior-move", warrior.key)
                            }
                            warrior.walkStatus = null
                        } else {
                            warrior.shouldMove = true
                        }
                    } else {
                        // console.log("setting for our warrior who just stopped", warrior.type, warrior.key, "at position", warrior.position, "their warrior as enemy", state.theirWarriors[enemyKey]?.type || "tower", enemyKey, state.theirWarriors[enemyKey]?.walkStatus ? "moving to" : "standing at", "position", state.theirWarriors[enemyKey]?.position || PLAYGROUND_POINTS_AMOUNT - 1, warrior.walkStatus === "walk" ? ", without stopping" : ", after stop")
                        warrior.walkStatus = null
                        warrior.enemyKey = enemyKey
                        warrior.shouldAttack = true
                    }
                }
            })
            .addCase(moveOpponentWarrior.pending, (state, action) => {
                const warrior = state.theirWarriors[action.meta.arg.key]
                if (warrior.moveStartMoments[warrior.position]) {
                    // console.log("Warning! Replacing existing moveStartMoment for their warrior", warrior.type, warrior.key, "for position", warrior.position)
                }
                warrior.moveStartMoments[warrior.position] = Date.now()
                // console.log("setting position of their warrior", warrior.type, warrior.key, "to index", PLAYGROUND_POINTS_AMOUNT - 1 - action.meta.arg.position, "at the moment", warrior.moveStartMoments[warrior.position], warrior.position < PLAYGROUND_POINTS_AMOUNT - 1 &&warrior.moveStartMoments[warrior.position] - warrior.moveStartMoments[warrior.position + 1], "ms after previous move started", warrior.walkStatus === "walk" ? ", without stopping" : ", after stop")
                warrior.willStop = action.meta.arg.withStop
                // This actually moves warrior since it goes to css
                warrior.position = PLAYGROUND_POINTS_AMOUNT - 1 - action.meta.arg.position
            })
            .addCase(moveOpponentWarrior.fulfilled, (state, action) => {
                const theirWarrior = state.theirWarriors[action.payload.warrior.key]
                // checking if theirWarrior wasn't killed or already urgently stopped
                if (theirWarrior?.walkStatus) {
                    if (action.payload.withStop) {
                        theirWarrior.walkStatus = null
                    } else if (state.isGameOver) {
                        theirWarrior.walkStatus = "stop"
                    }

                    if (!state.isGameOver) {
                        // checking if some our warrior should attack this warrior
                        for (const ourWarrior of Object.values(state.ourWarriors)) {
                            if (!ourWarrior.isKilled && !ourWarrior.walkStatus && ourWarrior.enemyKey === undefined) {
                                if (checkIfShouldAttack(ourWarrior, theirWarrior)) {
                                    // console.log("setting their warrior who just stopped", theirWarrior.type, theirWarrior.key, "as enemy for our warrior", ourWarrior.type, ourWarrior.key, "standing at position", ourWarrior.position, !ourWarrior.inBattle && "and force them to attack immediately")
                                    ourWarrior.enemyKey = theirWarrior.key
                                    if (!ourWarrior.inBattle) {
                                        ourWarrior.shouldAttack = true
                                    }
                                } else {
                                    // checking if warrior is standing and not fighting
                                    // console.log("checking if our standing still and not fighting warrior", ourWarrior.type, ourWarrior.key, "at position", ourWarrior.position, "should actually go. Trigger is moveOpponentWarrior.fulfilled of", theirWarrior.type, theirWarrior.key, "at position", theirWarrior.position)
                                    ourWarrior.shouldMove = canGo(
                                        ourWarrior,
                                        false,
                                        state.ourWarriors,
                                        state.theirWarriors
                                    )
                                    // if (!ourWarrior.shouldMove) {
                                    //     console.log("Previously checked warrior shouldn't go. May be they're waiting for their enemy to end move")
                                    // }
                                }
                            }
                        }
                    }
                }
            })
            .addCase(attack.pending, (state, action) => {
                const warrior = state.ourWarriors[action.meta.arg]
                if (!warrior.inBattle) {
                    warrior.inBattle = true
                    socket.emitEvent("start-battle", warrior.key)
                }
                warrior.shouldAttack = false
            })
            .addCase(attack.fulfilled, (state, action) => {
                const { key, newEnemyKey } = action.payload
                const warrior = state.ourWarriors[key]
                if (!warrior?.isKilled) {
                    warrior.isShooting = false

                    const { ourWarriors, theirWarriors, isGameOver } = state
                    if (isGameOver || newEnemyKey === undefined) {
                        socket.emitEvent("finish-battle", warrior.key)
                        warrior.inBattle = false
                        // console.log("deletting enemy for our warrior", warrior.type, warrior.key, "standing at position", warrior.position)
                        delete warrior.enemyKey
                        if (!isGameOver) {
                            // console.log("checking if our warrior", warrior.type, warrior.key, "should go after finishing battle at position", warrior.position)
                            warrior.shouldMove = canGo(warrior, false, ourWarriors, theirWarriors)
                            // if (!warrior.shouldMove) {
                            //     console.log("Previously checked warrior shouldn't go. May be they're waiting for their enemy to end move")
                            // }
                        }
                    } else {
                        // console.log("setting for our warrior", warrior.type, warrior.key, "just finished attack at position", warrior.position, "their warrior as enemy", theirWarriors[newEnemyKey]?.type || "tower", newEnemyKey, theirWarriors[newEnemyKey]?.walkStatus ? "moving to" : "standing at", "position", theirWarriors[newEnemyKey]?.position || PLAYGROUND_POINTS_AMOUNT - 1)
                        warrior.enemyKey = newEnemyKey
                        warrior.shouldAttack = true
                    }
                }
            })
            .addCase(opponentAttack.fulfilled, (state, action) => {
                const warrior = state.theirWarriors[action.payload]
                if (!warrior?.isKilled) {
                    // console.log("Stop shooting their", warrior.type, action.payload, Date.now())
                    warrior.isShooting = false
                    delete warrior.enemyKey
                }
            })
    },
    selectors: {
        selectOurWarriors: (state) => state.ourWarriors,
        selectTheirWarriors: (state) => state.theirWarriors,
        selectTowerHealths: (state) => state.towerHealths,
        selectIsGameOver: (state) => state.isGameOver,
    },
})

export const actions = {
    ...battlefieldSlice.actions,
    addWarrior,
    moveWarrior,
    moveOpponentWarrior,
    attack,
    opponentAttack,
}
export const selectors = battlefieldSlice.selectors
export default battlefieldSlice.reducer
