import styled from "styled-components"
import { adaptPx } from "utils/style-utils"
import exit from "./images/button_exit.svg"
import sound from "./images/button_sound.svg"
import soundOff from "./images/button_sound_off.svg"
import music from "./images/button_music.svg"
import musicOff from "./images/button_music_off.svg"

export const HeaderWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: ${adaptPx(10)};
    width: calc(100% - ${adaptPx(20)});
    z-index: 10;
    display: flex;
    justify-content: space-between;
`

export const RightPart = styled.div`
    display: inline-flex;
`

export const ButtonWrap = styled.div<{ $isHidden?: boolean }>`
    width: ${adaptPx(55)};
    height: ${adaptPx(55)};
    display: inline-block;
    cursor: pointer;
    visibility: ${(props) => (props.$isHidden ? "hidden" : "visible")};
    pointer-events: ${(props) => (props.$isHidden ? "none" : "auto")};
    position: relative;

    &:last-child {
        margin-left: ${adaptPx(10)};
    }
    &:hover {
        .hover {
            opacity: 0.2;
        }
    }
`

const Button = styled.div`
    width: 100%;
    height: 100%;
`

export const Hover = styled(Button)`
    position: absolute;
    left: 0;
    top: 0;
    background: white;
    opacity: 0;
    border-radius: 50%;
`

export const ExitButton = styled(Button)`
    background: url(${exit}) center/contain no-repeat;
`

export const SoundButton = styled(Button)<{ $isSoundOn: boolean }>`
    background: url(${(props) => (props.$isSoundOn ? sound : soundOff)}) center/contain no-repeat;
`

export const MusicButton = styled(Button)<{ $isMusicOn: boolean }>`
    background: url(${(props) => (props.$isMusicOn ? music : musicOff)}) center/contain no-repeat;
`

export const StarsWrap = styled.div`
    display: inline-block;
    vertical-align: top;
`
