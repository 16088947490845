import { BridgeWrap } from "./elements"
import { ReactComponent as BridgeImage } from "../../images/bridge.svg"

export const Bridge = ({ side }: { side: "left" | "right" }) => {
    return (
        <BridgeWrap $side={side}>
            <BridgeImage className="bridge-image" />
        </BridgeWrap>
    )
}
