import { ButtonS } from "./elements"

export const Button = ({
    disabled = false,
    blocked = false,
    onClick,
    width = undefined,
    id = undefined,
    selected = false,
    className,
    text,
}) => {
    return (
        <ButtonS
            onClick={disabled || blocked ? null : onClick}
            $width={width}
            id={id}
            $disabled={disabled}
            $blocked={blocked}
            $selected={selected}
            className={className}
        >
            {text}
        </ButtonS>
    )
}
