import { useEffect, useRef, useState } from "react"
import Lottie from "lottie-react"
import { Menu } from "shared/ui/components/menu"
import warriors from "static/warriors.json"
import { avatarAnimations } from "./animations"
import { staticWarriors } from "shared/images/warriors"
import {
    WarriorInfoWrap,
    MenuWrap,
    WarriorsMenuWrap,
    BigAvatar,
    StatsSeparator,
    StatsWrap,
    AvatarAndStatsWrap,
    AvatarAndStatsLine,
    StatsLine,
    StatsName,
    StatsValue,
    InfoBlock,
    PurchaseLine,
    PurchaseText,
    LittleAvatar,
} from "./elements"

export const WarriorsMenu = () => {
    const [openedWarriorType, setOpenedWarriorType] = useState(null)
    const lottieRef = useRef(null)

    const onButtonClickHandler = (e) => {
        setOpenedWarriorType(e.target.id)
    }

    const mapButtonsProps = () =>
        warriors.map((warrior) => ({
            text: warrior.name,
            width: 200,
            id: warrior.type,
            disabled: false,
            selected: warrior.type === openedWarriorType,
            onClick: onButtonClickHandler,
        }))
    const warrior = warriors.find((el) => el.type === openedWarriorType)
    let animationOptions
    let AvatarSVG = null
    if (warrior) {
        animationOptions = {
            loop: false,
            autoplay: true,
            animationData: avatarAnimations[warrior.type],
        }
        AvatarSVG = staticWarriors[warrior.type]
    }

    useEffect(() => {
        if (lottieRef?.current) {
            lottieRef.current.setSpeed(0.8)
        }
    }, [warrior])

    return (
        <WarriorsMenuWrap>
            {warrior ? (
                <WarriorInfoWrap className={`${warrior.type} our`}>
                    <AvatarAndStatsLine>
                        <AvatarAndStatsWrap>
                            <BigAvatar>
                                <Lottie {...animationOptions} lottieRef={lottieRef} />
                            </BigAvatar>
                            <StatsWrap>
                                <StatsLine>
                                    <StatsName>Жизнь</StatsName>
                                    <StatsValue>{warrior.stats.health}</StatsValue>
                                </StatsLine>
                                <StatsSeparator />
                                <StatsLine>
                                    <StatsName>Урон</StatsName>
                                    <StatsValue>{warrior.stats.attack}</StatsValue>
                                </StatsLine>
                                <StatsSeparator />
                                <StatsLine>
                                    <StatsName>Скорость</StatsName>
                                    <StatsValue>{warrior.stats.speed}</StatsValue>
                                </StatsLine>
                            </StatsWrap>
                        </AvatarAndStatsWrap>
                    </AvatarAndStatsLine>
                    <InfoBlock>{warrior.description}</InfoBlock>
                    <PurchaseLine>
                        <LittleAvatar>
                            <AvatarSVG className="AvatarSVG" />
                        </LittleAvatar>
                        {/* TODO: не забыть проапдейтить */}
                        <PurchaseText>
                            ***Here will some buying info about {warrior.type}
                            ***
                        </PurchaseText>
                    </PurchaseLine>
                </WarriorInfoWrap>
            ) : (
                <WarriorInfoWrap $isHidden />
            )}
            <MenuWrap>
                <Menu buttons={mapButtonsProps()} />
            </MenuWrap>
        </WarriorsMenuWrap>
    )
}
