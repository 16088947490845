import fairyStart from "./fairy/fairy_start.json"
import fairyWalk from "./fairy/fairy_walk.json"
import fairyStop from "./fairy/fairy_stop.json"

import dwarfStart from "./dwarf/dwarf_start.json"
import dwarfWalk from "./dwarf/dwarf_walk.json"
import dwarfStop from "./dwarf/dwarf_stop.json"

import knightStart from "./knight/knight_start.json"
import knightWalk from "./knight/knight_walk.json"
import knightStop from "./knight/knight_stop.json"

import wizardStart from "./wizard/wizard_start.json"
import wizardStop from "./wizard/wizard_stop.json"
import wizardWalk from "./wizard/wizard_walk.json"

import skeletonStart from "./skeleton/skeleton_start.json"
import skeletonWalk from "./skeleton/skeleton_walk.json"
import skeletonStop from "./skeleton/skeleton_stop.json"

export const walkAnimations = {
    fairy: {
        start: fairyStart,
        walk: fairyWalk,
        stop: fairyStop,
    },
    dwarf: {
        start: dwarfStart,
        walk: dwarfWalk,
        stop: dwarfStop,
    },
    knight: {
        start: knightStart,
        walk: knightWalk,
        stop: knightStop,
    },
    wizard: {
        start: wizardStart,
        walk: wizardWalk,
        stop: wizardStop,
    },
    skeleton: {
        start: skeletonStart,
        walk: skeletonWalk,
        stop: skeletonStop,
    },
}
