import styled from "styled-components"
import { adaptPx } from "utils/style-utils"

export const WarriorsMenuWrap = styled.div`
    display: flex;
`

const gradient = "linear-gradient(to bottom, #ffffff 0%, #ffffff 80%, #D6F8FF 80%, #D6F8FF 100%)"

export const WarriorInfoWrap = styled.div<{ $isHidden?: boolean }>`
    height: ${adaptPx(415)};
    width: calc(${adaptPx(710 - 260)});
    padding-left: ${adaptPx(260)};
    background: ${(props) => (props.$isHidden ? "transparent" : gradient)};
    border-radius: ${adaptPx(20)};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    &.fairy .AvatarSVG {
        width: ${adaptPx(51.563)};
        height: ${adaptPx(59.407)};
        bottom: ${adaptPx(-11)};
    }
    &.dwarf .AvatarSVG {
        width: ${adaptPx(50.188)};
        height: ${adaptPx(56.162)};
        bottom: ${adaptPx(-7.563)};
    }
    &.knight .AvatarSVG {
        width: ${adaptPx(45.375)};
        height: ${adaptPx(60.5)};
        bottom: ${adaptPx(-6.188)};
    }
    &.skeleton .AvatarSVG {
        width: ${adaptPx(48.125)};
        height: ${adaptPx(75.02)};
        bottom: ${adaptPx(-4.125)};
    }
    &.wizard .AvatarSVG {
        width: ${adaptPx(45.375)};
        height: ${adaptPx(61.978)};
        bottom: ${adaptPx(-6.875)};
    }
`

export const MenuWrap = styled.div`
    position: absolute;
    margin-top: ${adaptPx(-5)};
`

export const AvatarAndStatsLine = styled.div`
    display: flex;
    justify-content: space-around;
`

export const AvatarAndStatsWrap = styled.div`
    display: flex;
    width: 87%;
    justify-content: space-between;
`

export const BigAvatar = styled.div`
    height: ${adaptPx(200)};
    width: ${adaptPx(200)};
    background-color: #7fb7e0;
    border-radius: ${adaptPx(10)};
    pointer-events: none;
`

export const StatsWrap = styled.div`
    height: ${adaptPx(200)};
    width: ${adaptPx(175)};
    background-color: #d6f8ff;
    border-radius: ${adaptPx(10)};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`

export const StatsSeparator = styled.div`
    position: relative;
    height: ${adaptPx(2)};
    width: 90%;
    left: 5%;
    background-color: #3f9cc4;
    border-radius: ${adaptPx(2)};
    margin-bottom: ${adaptPx(-20)};
    margin-top: ${adaptPx(-20)};
`

export const StatsLine = styled.div`
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    justify-content: space-between;
`

export const StatsName = styled.div`
    color: #3f9cc4;
    font-weight: 600;
    font-size: ${adaptPx(18)};
    line-height: ${adaptPx(25)};
    text-align: left;
`

export const StatsValue = styled.div`
    color: #0a5c8a;
    font-weight: 600;
    font-size: ${adaptPx(18)};
    line-height: ${adaptPx(25)};
    text-align: right;
`

export const InfoBlock = styled.div`
    font-size: ${adaptPx(16)};
    line-height: ${adaptPx(22)};
    color: #3f9cc4;
    white-space: pre-wrap;
    padding-left: ${adaptPx(30)};
`

export const PurchaseLine = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: ${adaptPx(-10)};
`

export const LittleAvatar = styled.div`
    display: flex;
    justify-content: center;
    height: ${adaptPx(55)};
    width: ${adaptPx(55)};
    background-color: #3f9cc4;
    border-radius: ${adaptPx(10)};
    overflow: hidden;

    .AvatarSVG {
        position: relative;
    }
`

export const PurchaseText = styled.div`
    color: #00508a;
    font-size: ${adaptPx(16)};
    line-height: ${adaptPx(21)};
    white-space: pre-wrap;
    display: flex;
    align-items: center;
`
