import styled from "styled-components"
import { adaptPx } from "utils/style-utils"

export const PreGamePageWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const Title = styled.div`
    font-weight: bold;
    font-size: ${adaptPx(32)};
    line-height: ${adaptPx(44)};
    color: #ffffff;
    margin-top: ${adaptPx(10)};
    margin-bottom: ${adaptPx(25)};
`
