import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { App } from "./features/app"
import * as serviceWorker from "./serviceWorker"
import { store } from "./config/store"
import "./index.css"

const container = document.getElementById("root")
const root = createRoot(container)
root.render(<Provider store={store} children={<App />} />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
