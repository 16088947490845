import { useSelector } from "react-redux"
import { useEffect } from "react"
import { useAppDispatch } from "config/store"
import { MAX_COINS_AMOUNT } from "shared/constants/gameplayConstants"
import * as duck from "./duck"
import * as purchaseDuck from "features/purchase/duck"
import * as battlefieldDuck from "features/battlefield/duck"
import warriors from "static/warriors.json"
import { WarriorCard } from "./comoponents"
import type { WarriorTemplate } from "./duck/types"
import { CoinsAmount, CoinsWrap, WarriorsSelectorWrap } from "./elements"

export const WarriorsSelector = () => {
    const dispatch = useAppDispatch()
    const selectedWarrior = useSelector(duck.selectors.selectSelectedWarrior)
    const coinsAmount = useSelector(purchaseDuck.selectors.selectCoinsAmount)
    const isIncreasingCoins = useSelector(purchaseDuck.selectors.selectIsIncreasingCoins)
    const isGameOver = useSelector(battlefieldDuck.selectors.selectIsGameOver)

    const warriorClickHandler = (e) => {
        if (selectedWarrior) return
        const warrior: WarriorTemplate = warriors.find((el) => el.type === e.target.id)

        // dispatch(purchaseDuck.actions.activatePurchase(warrior.type))
        dispatch(duck.actions.selectWarrior(warrior))
    }

    useEffect(() => {
        if (isGameOver || (coinsAmount === MAX_COINS_AMOUNT && isIncreasingCoins)) {
            dispatch(purchaseDuck.actions.stopCoinsIncrease())
        } else if (coinsAmount < MAX_COINS_AMOUNT && !isIncreasingCoins) {
            dispatch(purchaseDuck.actions.startCoinsIncrease())
        }
    }, [dispatch, isGameOver, coinsAmount, isIncreasingCoins])

    return (
        <WarriorsSelectorWrap>
            {warriors.map((warrior) => (
                <WarriorCard
                    key={warrior.type}
                    warrior={warrior}
                    isSelected={warrior.type === selectedWarrior?.type}
                    isDisabled={coinsAmount < warrior.price}
                    warriorClickHandler={warriorClickHandler}
                />
            ))}

            <CoinsWrap>
                Coins:
                <CoinsAmount>{coinsAmount}</CoinsAmount>
            </CoinsWrap>
        </WarriorsSelectorWrap>
    )
}
