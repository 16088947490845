import { MenuWrap, ButtonWrap } from "./elements"
import { Button } from "shared/ui/components/button"

export const Menu = ({ buttons }) => {
    return (
        <MenuWrap>
            {buttons.map((button) => (
                <ButtonWrap key={button.id}>
                    <Button {...button} />
                </ButtonWrap>
            ))}
        </MenuWrap>
    )
}
