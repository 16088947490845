import styled from "styled-components"
import { adaptPx } from "utils/style-utils"

export const BattlefieldWrap = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    bottom: ${adaptPx(120)};
    height: calc(100% - ${adaptPx(195)});
`

export const BridgesWrap = styled.div`
    position: absolute;
    width:  ${adaptPx(1024)};
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
`
