import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ThemeProvider } from "styled-components"
import { useAppDispatch } from "config/store"
import * as duck from "./duck"
import * as colorSelectorDuck from "features/colorSelector/duck"
import * as lobbyDuck from "features/lobby/duck"
import { MenuPage } from "pages/menuPage"
import { WarriorsPage } from "pages/warriorsPage"
import { PreGamePage } from "pages/preGamePage"
import { GamePage } from "pages/gamePage"
import { calcDesignPxSize } from "utils/style-utils"
import { debounce } from "utils/debounce"
import { getTheme } from "shared/constants/themes"
import { Background, GlobalWarriorsStyles } from "./elements"

export const App = () => {
    const dispatch = useAppDispatch()
    const currentPage = useSelector(duck.selectors.selectCurrentPage)
    const [designPxSize, setDesignPxSize] = useState(1)

    const pageSwitcher = () => {
        switch (currentPage) {
            case "menu":
                return <MenuPage />
            case "warriors":
                return <WarriorsPage />
            case "preGame":
                return <PreGamePage />
            case "game":
                return <GamePage />
            default:
                return <></>
        }
    }

    useEffect(() => {
        setDesignPxSize(calcDesignPxSize())
        const listener = debounce(() => setDesignPxSize(calcDesignPxSize()), 50)
        window.addEventListener("resize", listener)
        return () => window.removeEventListener("resize", listener)
    }, [])

    useEffect(() => {
        dispatch(duck.actions.initialize())
    }, [dispatch])

    const colorName = useSelector(colorSelectorDuck.selectors.selectColorName)
    const opponentColorName = useSelector(lobbyDuck.selectors.selectOpponentColor)

    return (
        <ThemeProvider theme={getTheme(colorName, opponentColorName, designPxSize)}>
            <Background $isInGame={currentPage === "game"} />
            <GlobalWarriorsStyles />
            {pageSwitcher()}
        </ThemeProvider>
    )
}
