import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit"
import type { AppDispatch, AppState } from "config/types"
import type { WarriorTemplate } from "features/warriorsSelector/duck/types"
import { COINS_INCREASE_DELAY_MS, MAX_COINS_AMOUNT } from "shared/constants/gameplayConstants"
import keyMirror from "utils/keymirror"

const FEATURE_NAME = "purchase"

const actionTypes = keyMirror(
    {
        ACTIVATE_PURCHASE: null,
        CLOSE_PURCHASE: null,
        START_COINS_INCREASE: null,
    },
    FEATURE_NAME
)

const initialState = {
    isPurchaseActive: false,
    purchaseType: null as WarriorTemplate["type"] | null,
    isPurchaseSuccessful: false,
    coinsAmount: MAX_COINS_AMOUNT,
    isIncreasingCoins: false,
}

const activatePurchase = createAsyncThunk(
    actionTypes.ACTIVATE_PURCHASE,
    (purchaseType: WarriorTemplate["type"]) => purchaseType
)

const closePurchase = createAsyncThunk(
    actionTypes.CLOSE_PURCHASE,
    (isSuccessful: boolean) => isSuccessful
)

const startCoinsIncrease = createAsyncThunk<void, undefined, { state: AppState, dispatch: AppDispatch }>(
    actionTypes.START_COINS_INCREASE,
    async (_, thunkAPI) => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                const { isIncreasingCoins, coinsAmount } = thunkAPI.getState()[FEATURE_NAME]
                if (isIncreasingCoins && coinsAmount < MAX_COINS_AMOUNT - 1) {
                    thunkAPI.dispatch(startCoinsIncrease())
                }
                resolve()
            }, COINS_INCREASE_DELAY_MS);
        })
    }
)

const purchaseSlice = createSlice({
    name: FEATURE_NAME,
    initialState,
    reducers: {
        decreaseCoinsAmount: (state, action: PayloadAction<number>) => {
            state.coinsAmount -= action.payload
        },
        stopCoinsIncrease: (state) => {
            state.isIncreasingCoins = false
        },
        resetPurchase: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(activatePurchase.pending, (state) => {
                state.isPurchaseSuccessful = false
            })
            .addCase(activatePurchase.fulfilled, (state, action) => {
                state.purchaseType = action.payload
                state.isPurchaseActive = true
            })
            .addCase(closePurchase.fulfilled, (state, action) => {
                state.isPurchaseActive = false
                state.purchaseType = null
                state.isPurchaseSuccessful = action.payload
            })
            .addCase(startCoinsIncrease.pending, (state) => {
                state.isIncreasingCoins = true
            })
            .addCase(startCoinsIncrease.fulfilled, (state) => {
                const { coinsAmount, isIncreasingCoins } = state
                if (isIncreasingCoins && coinsAmount < MAX_COINS_AMOUNT) {
                    state.coinsAmount++
                }
            })
    },
    selectors: {
        selectIsPurchaseActive: (state) => state.isPurchaseActive,
        selectPurchaseType: (state) => state.purchaseType,
        selectIsPurchaseSuccessful: (state) => state.isPurchaseSuccessful,
        selectCoinsAmount: (state) => state.coinsAmount,
        selectIsIncreasingCoins: (state) => state.isIncreasingCoins,
    },
})

export const actions = { ...purchaseSlice.actions, activatePurchase, closePurchase, startCoinsIncrease }
export const selectors = purchaseSlice.selectors
export default purchaseSlice.reducer
