import { MenuPageWrap, Title } from "./elements"
import { MainMenu } from "features/shared/mainMenu"
import { Header } from "features/shared/header"

export const MenuPage = () => {
    return (
        <MenuPageWrap>
            <Header stars canExitFromGame />
            <Title>БАШНИИИИИИИ</Title>
            <MainMenu />
        </MenuPageWrap>
    )
}
