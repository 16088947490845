import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { BUTTONS_KEYS } from "features/shared/mainMenu"

import keyMirror from "utils/keymirror"

const FEATURE_NAME = "app"

const actionTypes = keyMirror(
    {
        INITIALIZE: null,
        TOGGLE_PAGE: null,
        EXIT_FROM_GAME: null,
    },
    FEATURE_NAME
)

const initialize = createAsyncThunk(actionTypes.INITIALIZE, () => {})

const togglePage = createAsyncThunk(
    actionTypes.TOGGLE_PAGE,
    (id: string) => id
)

const exitFromGame = createAsyncThunk(
    actionTypes.EXIT_FROM_GAME,
    (path: string) => path
)

const initialState = {
    ready: false,
    currentPage: "loading",
    withFriends: false,
    exitPath: "",
}

const appSlice = createSlice({
    name: FEATURE_NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(initialize.pending, (state) => {
                state.ready = false
                state.currentPage = "loading"
            })
            .addCase(initialize.fulfilled, (state) => {
                state.ready = true
                state.exitPath = "" //TODO: достать из инициализация
                state.currentPage = "menu"
            })
            .addCase(initialize.rejected, (state) => {
                state.ready = true
                state.currentPage = "error"
            })
            .addCase(togglePage.fulfilled, (state, action) => {
                switch (action.payload) {
                    case BUTTONS_KEYS[0]:
                        state.currentPage = "preGame"
                        state.withFriends = false
                        break
                    case BUTTONS_KEYS[1]:
                        state.currentPage = "preGame"
                        state.withFriends = true
                        break
                    case BUTTONS_KEYS[2]:
                        state.currentPage = "warriors"
                        state.withFriends = false
                        break
                    default:
                        state.currentPage = action.payload
                        state.withFriends = false
                }
            })
            .addCase(exitFromGame.fulfilled, (_, action) => {
                window.location.assign(`/${action.payload}`)
            })
    },
    selectors: {
        selectExitPath: (state) => state.exitPath,
        selectCurrentPage: (state) => state.currentPage,
    },
})

export const actions = { initialize, togglePage, exitFromGame }
export const selectors = appSlice.selectors
export default appSlice.reducer
