import { useEffect, useRef } from "react"
import { debounce } from "utils/debounce"
import { playgroundPoints } from "shared/constants/playgroundPoints"
import { Warrior } from "./components/warrior"
import type { AppState } from "config/types"
import type { ColorObj } from "features/colorSelector/duck/types"
import { PlaygroundWrap, PointsWrap, Point, WarriorsWrap } from "./elements"

type PlaygroundProps = {
    ourWarriors: AppState["battlefield"]["ourWarriors"],
    theirWarriors: AppState["battlefield"]["theirWarriors"],
    colorObj: ColorObj,
    opponentColorObj: ColorObj,
}

export const Playground = ({ ourWarriors, theirWarriors, colorObj, opponentColorObj }: PlaygroundProps) => {
    const pointsRefs = useRef<HTMLDivElement[]>([])

    const setPointsOffsets = () => {
        playgroundPoints.forEach((point, i) => {
            point.offset = pointsRefs.current[i].offsetLeft
        })
    }

    useEffect(() => {
        setTimeout(() => {
            // without this timeout sometimes offsets are calculated a bit wrongly
            setPointsOffsets()
        }, 50);
        const listener = debounce(() => setPointsOffsets(), 50)
        window.addEventListener("resize", listener)
        return () => window.removeEventListener("resize", listener)
    }, [])

    return (
        <PlaygroundWrap>
            <PointsWrap>
                {playgroundPoints.map((_, i) => (
                    <Point key={i} ref={(el) => (pointsRefs.current[i] = el)} />
                ))}
            </PointsWrap>
            <WarriorsWrap>
                {Object.values(theirWarriors).map((warrior) => (
                    <Warrior
                        key={warrior.key}
                        warrior={warrior}
                        currentHealth={warrior.currentHealth}
                    />
                ))}
                {Object.values(ourWarriors).map((warrior) => (
                    <Warrior
                        key={warrior.key}
                        warrior={warrior}
                        currentHealth={warrior.currentHealth}
                    />
                ))}
            </WarriorsWrap>
        </PlaygroundWrap>
    )
}
