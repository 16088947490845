import styled from "styled-components"
import { adaptPx } from "utils/style-utils"
import star from "./images/star.svg"
import starPlace from "./images/star_place.svg"
import hintStar from "./images/hint_star.svg"

export const StarsWrap = styled.div`
    display: flex;
    cursor: pointer;
    border: ${adaptPx(2)} solid transparent;
    box-sizing: border-box;
    border-radius: ${adaptPx(10)};
    padding: ${adaptPx(8)};
    position: relative;

    &:hover {
        border: ${adaptPx(2)} solid #61ebff;
        .hint {
            visibility: visible;
        }
    }
`

export const StarWrap = styled.div`
    width: ${adaptPx(35)};
    height: ${adaptPx(34)};
    position: relative;
    margin: 0 ${adaptPx(6)};
`

export const StarPlace = styled.div`
    width: 100%;
    height: 100%;
    background: url(${starPlace}) center/contain no-repeat;
`

export const Star = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${star}) center/contain no-repeat;
`

export const Hint = styled.div`
    position: absolute;
    top: ${adaptPx(60)};
    left: 50%;
    transform: translateX(-50%);
    width: ${adaptPx(230)};
    height: ${adaptPx(170)};
    background: #61ebff;
    border-radius: ${adaptPx(8)};
    visibility: hidden;
    padding: 0 ${adaptPx(10)};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`

export const Text = styled.div`
    color: #0a5c8a;
    font-size: ${adaptPx(16)};
    line-height: ${adaptPx(24)};
    display: inline-block;
    text-align: center;
`

export const HintStar = styled.div`
    width: ${adaptPx(17)};
    height: ${adaptPx(16)};
    background: url(${hintStar});
    display: inline-block;
`

export const Separator = styled.span`
    width: 100%;
    height: ${adaptPx(2)};
    background: #0a5c8a;
`

export const Timer = styled(Text)`
    font-weight: bold;
`
