import { useDispatch } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import * as app from "features/app/duck"
import * as socket from "features/socket/duck"
import * as colorSelector from "features/colorSelector/duck"
import * as warriorsSelector from "features/warriorsSelector/duck"
import * as purchase from "features/purchase/duck"
import * as battlefield from "features/battlefield/duck"
import * as lobby from "features/lobby/duck"
import * as header from "features/shared/header/duck"
import * as stars from "features/shared/stars/duck"
import { socketMiddleware } from "./socketMiddleware"
import type { AppDispatch } from "./types"

export const store = configureStore({
    reducer: {
        app: app.reducer,
        socket: socket.reducer,
        colorSelector: colorSelector.reducer,
        warriorsSelector: warriorsSelector.reducer,
        purchase: purchase.reducer,
        battlefield: battlefield.reducer,
        header: header.reducer,
        lobby: lobby.reducer,
        stars: stars.reducer,
    },
    middleware(getDefaultMiddleware) {
        return getDefaultMiddleware().concat([socketMiddleware])
    },
})

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
