import { PLAYGROUND_POINTS_AMOUNT } from "shared/constants/playgroundPoints"
import type { WarriorAtBattlefield, TheirWarrior, OurWarrior } from "./types"

export const canGo = (
    warrior: WarriorAtBattlefield,
    /** Have we just started moving to our warrior.position (then true); or are we re-checking on approaching it (then false) or standing at it (also false) */
    isAlreadyMoving: boolean,
    ourWarriors: Record<WarriorAtBattlefield["key"], OurWarrior>,
    theirWarriors: Record<WarriorAtBattlefield["key"], TheirWarrior>,
    /** previous !withStop when re-checking on approaching the point */
    previousCanGo?: boolean,
    isWalkAdvantage?: boolean
): boolean => {
    let result: boolean
    if (warrior.position === PLAYGROUND_POINTS_AMOUNT - 1 - warrior.stats.distance) {
        // console.log("We", warrior.type, warrior.key, "are", isAlreadyMoving ? "approaching" : "standing at", "the point from which we can attack tower")
        result = false
    } else {
        /** The point from which we need to estimate distances from enemies */
        const decisionPoint = warrior.position + 1 + warrior.stats.distance
        const moveStartMoment = isAlreadyMoving ? warrior.moveStartMoments[warrior.position - 1] : Date.now()
        /** moment when we get the point from which we would be able to attack our Decision point */
        const moveEndTime: number = moveStartMoment + (isAlreadyMoving ? warrior.stats.pace * 2 : warrior.stats.pace)

        for (const teammate of Object.values(ourWarriors)) {
            if (teammate.isKilled) continue
            /** amount of points between our teammate start-moving point (or previous point if their're standing) and our Decision point */
            const dist: number = decisionPoint - (teammate.position - 1)
            if (dist <= 1) {
                // console.log("Teammate warrior", teammate.type, teammate.key, "is approaching or standing at our (", warrior.type, warrior.key, ") Decision point", decisionPoint, "(", PLAYGROUND_POINTS_AMOUNT - 1 - decisionPoint, ") or even further, which means there isn't any enemies before our Decision point")
                result = true
                break
            }
        }

        if (result === undefined && Object.keys(theirWarriors).length > 0) {
            /** their warrior which will first reach our Decision point  */
            let closestEnemy: TheirWarrior
            /** points amount between their warrior's previous position (if they are already moving — start position of current move) and our Decision point */
            let distance: number
            /** the moment when closestEnemy gets to our Decision point */
            let minTime: number = Infinity
            for (const enemy of Object.values(theirWarriors)) {
                if (enemy.isKilled) continue

                const dist = enemy.position + 1 - decisionPoint
                if (dist < 0) {
                    result = false
                    // console.log("their warrior", enemy.type, enemy.key, "already passed our warrior's (", warrior.type, warrior.key, ") decision point", decisionPoint, warrior.stats.distance ? "and should be attacked" : "Warning! They passed by each other")
                    // to avoid last block of calculations — because the result is already clear
                    closestEnemy = undefined
                    break
                } else if (dist === 0) {
                    result = false
                    // to avoid last block of calculations — because the result is already clear
                    closestEnemy = undefined
                    // console.log("Their warrior", enemy.type, enemy.key, enemy.walkStatus ? "is approaching" : "is standing at", "the point", enemy.position, "which we can attack from", isAlreadyMoving ? "the point we're approaching" : "our point", warrior.position, "(", PLAYGROUND_POINTS_AMOUNT - warrior.position - 1, ")", "(we:", warrior.type, warrior.key, ")")
                    break
                } else if (enemy.walkStatus) {
                    // tweak for reducing the impact of transmition delays — if estimated time of ending move has already passed, but we haven't received next command, let's assume the warrior makes next move starting from this moment
                    const isNetworkDelay = enemy.moveStartMoments[enemy.position + 1] + enemy.stats.pace < Date.now()
                    const startMoment = isNetworkDelay ? Date.now() : enemy.moveStartMoments[enemy.position + 1]
                    const moveDuration = enemy.stats.pace * (isNetworkDelay ? dist : dist + 1)
                    const time = startMoment + moveDuration
                    // console.log("Their moving warrior", enemy.type, enemy.key, "position", enemy.position, "dist", dist, "time", time)
                    if (time < minTime) {
                        closestEnemy = enemy
                        distance = dist
                        minTime = time
                    }
                }
            }

            if (closestEnemy) {
                // console.log("Distance between closest enemy start moving point and our Decision point", decisionPoint, "—", distance)
                if (closestEnemy.willStop && distance > 1) {
                    // console.log("Closest enemy", closestEnemy.type, closestEnemy.key, "is going to stop at", closestEnemy.position, "which is", distance - 1, "points away from our (", warrior.type, warrior.key, ") Decision point", decisionPoint, "(", PLAYGROUND_POINTS_AMOUNT - 1 - decisionPoint, ")")
                    result = true
                } else {
                    if (!warrior.stats.distance && distance === 1 && !isAlreadyMoving) {
                        if (previousCanGo === undefined) {
                            // console.log("We're standing and not able to shoot and the closest enemy", closestEnemy.type, closestEnemy.key, "is approaching our (", warrior.type, warrior.key, ") Decision point", decisionPoint, "(", PLAYGROUND_POINTS_AMOUNT - 1 - decisionPoint, ") and", closestEnemy.willStop ? "going" : "not going", "to stop")
                            result = closestEnemy.willStop
                        } else if (closestEnemy.willStop === previousCanGo) {
                            // console.log("No need to change previous decision", previousCanGo)
                            result = previousCanGo} else if (warrior.stats.pace === closestEnemy.stats.pace) {
                            // console.log("The closest enemy", closestEnemy.type, closestEnemy.key, "and we", warrior.type, warrior.key, "both were going to", previousCanGo ? "go," : "stop,", " and our speeds are equal. Our advantage:", isWalkAdvantage)
                            result = isWalkAdvantage
                        } else {
                            // console.log("The closest enemy", closestEnemy.type, closestEnemy.key, "and we", warrior.type, warrior.key, "both were going to", previousCanGo ? "go," : "stop,", "but we're", warrior.stats.pace < closestEnemy.stats.pace ? "faster" : "slower")
                            result = warrior.stats.pace < closestEnemy.stats.pace
                        }
                    } else {
                        // console.log("The closest enemy", closestEnemy.type, closestEnemy.key, "will get to our Decision point", decisionPoint, "(", PLAYGROUND_POINTS_AMOUNT - 1 - decisionPoint, moveEndTime < minTime ? ") after" : ") before", "we (", warrior.type, warrior.key, ") get to the point", decisionPoint - warrior.stats.distance, "from which we can attack them", moveEndTime, minTime)
                        result = moveEndTime < minTime
                    }
                }
            }
        }
    }

    if (result === undefined) result = true

    // console.log("canGo", warrior.type, warrior.key, "position", warrior.position, "isAlreadyMoving", isAlreadyMoving, "() =>", result)
    return result
}

/** apply only for our not killed (isKilled === false) standing (walkStatus === null) or ending their move our warriors (they should stop if enemy was found) */
export const findEnemy = (
    ourWarrior: OurWarrior,
    theirWarriors: { [n: number]: TheirWarrior }
): OurWarrior["enemyKey"] => {
    let enemyKey: OurWarrior["enemyKey"]
    let minDistance: number

    for (const theirWarrior of Object.values(theirWarriors)) {
        if (theirWarrior.isKilled) continue

        const distance = theirWarrior.walkStatus
            ? theirWarrior.position + 1 - ourWarrior.position
            : theirWarrior.position - ourWarrior.position
        if (ourWarrior.stats.distance) {
            // we can shoot
            if (distance <= ourWarrior.stats.distance && (minDistance === undefined || distance < minDistance)) {
                enemyKey = theirWarrior.key
                minDistance = distance
            }
        } else {
            // we can't shoot
            if (!theirWarrior.walkStatus && distance === 0) {
                enemyKey = theirWarrior.key
                // consider attacking the enemy with the lowest key, if there is a choice. So we can stop iteration over theirWarriors because they're most likely sorted by key
                break
            }
        }
    }

    if (enemyKey === undefined && ourWarrior.position === PLAYGROUND_POINTS_AMOUNT - 1 - ourWarrior.stats.distance)
        enemyKey = "tower"
    // console.log("findEnemy", ourWarrior.type, ourWarrior.key, "position", ourWarrior.position, "=>", theirWarriors[enemyKey]?.type, enemyKey)
    return enemyKey
}

/** apply only for our standing (walkStatus === null) and not killed (isKilled === false) warriors*/
export const checkIfShouldAttack = (ourWarrior: OurWarrior, theirWarrior: TheirWarrior) =>
    (ourWarrior.enemyKey === undefined || ourWarrior.enemyKey === "tower") &&
    theirWarrior.position - ourWarrior.position <= ourWarrior.stats.distance
