import { useSelector } from "react-redux"
import { Button } from "shared/ui/components/button"
import * as appDuck from "features/app/duck"
import * as battlefieldDuck from "features/battlefield/duck"
import * as purchaseDuck from "features/purchase/duck"
import { GameOverWrap, Result, ButtonWrap } from "./elements"
import { useAppDispatch } from "config/store"

export const GameOver = () => {
    const dispatch = useAppDispatch()
    const towerHealths = useSelector(battlefieldDuck.selectors.selectTowerHealths)

    const buttonClickHandler = () => {
        dispatch(battlefieldDuck.actions.resetBattle())
        dispatch(purchaseDuck.actions.resetPurchase())
        // this also disconnects & terminates socket in middleware
        dispatch(appDuck.actions.togglePage("menu"))
    }

    return (
        <GameOverWrap>
            <Result>
                <h3>Game over! You {towerHealths.our > 0 ? "won!" : "lose."}</h3>
            </Result>
            <ButtonWrap>
                <Button text="Back to menu" className="returnButton" onClick={buttonClickHandler} />
            </ButtonWrap>
        </GameOverWrap>
    )
}
