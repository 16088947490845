import styled, { css, keyframes } from "styled-components"
import { adaptPx } from "utils/style-utils"
import { WEAPON_FLIGHT_DURATION_MS } from "shared/constants/gameplayConstants"

export const Health = styled.div<{ $healthKoeff: number }>`
    background: #ffffff;
    opacity: 0.8;
    border-radius: ${adaptPx(10)};
    width: ${adaptPx(7)};
    height: calc(${adaptPx(30)} * ${(props) => props.$healthKoeff});
    position: absolute;
    bottom: 110%;
    left: 50%;
    transition: height 0.2s;
    transform: translateX(-50%);
`

const getFlyingWeaponAnimation = (distance: number) => keyframes`
    0% {
        transform: translate(0, 0) rotate(0);
    }
    50% {
        transform: translate(${distance / 2}px, ${-distance / 5}px) rotate(360deg);
    }
    100% {
        transform: translate(${distance}px, 0) rotate(720deg);
    }
`

export const LottieWrap = styled.div<{ $isShooting?: boolean; $weaponFlyDistance?: number }>`
    bottom: 0;
    position: absolute;

    .flying_weapon {
        display: ${(props) => (props.$isShooting ? "block" : "none")};
        position: absolute;
        background-size: cover;
        background-position: center;
        animation: ${({ $weaponFlyDistance }) => getFlyingWeaponAnimation($weaponFlyDistance)}
            ${WEAPON_FLIGHT_DURATION_MS}ms ease-in forwards;
    }
`

export const WarriorWrap = styled.div<{
    $transitionTime: number
    $left: number
    $opposite: boolean
    $inBattle: boolean
    $isHurt: boolean
    $isKilled: boolean
}>`
    display: inline-block;
    position: absolute;
    left: 0;
    pointer-events: none;
    transition: transform ${(props) => props.$transitionTime}s linear;
    transform: translate(calc(${(props) => (props.$opposite ? "0%" : "-100%")} + ${(props) => props.$left}px), -100%)
        ${(props) => (props.$opposite ? "scale(-1, 1)" : "")};
    opacity: ${({ $isKilled }) => $isKilled ? 0.2 : 1};

    ${({ $isHurt }) =>
        $isHurt &&
        css`
            .WarriorSVG svg,
            ${LottieWrap} svg:not(.flying_weapon) {
                filter: drop-shadow(0 0 ${adaptPx(2)} red);
            }
        `}

    .WarriorSVG {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &.fairy {
        width: ${adaptPx(45)};
        height: ${adaptPx(52)};

        ${LottieWrap} {
            ${(props) =>
                props.$inBattle
                    ? css`
                          width: 148%;
                          height: 105%;
                      `
                    : css`
                          width: 150%;
                          height: 106%;
                      `}
        }

        .WarriorSVG {
            left: 15%;
        }
    }
    &.dwarf {
        width: ${adaptPx(41)};
        height: ${adaptPx(46)};

        ${LottieWrap} {
            width: 137%;
            height: 106%;

            ${(props) =>
                props.$inBattle
                    ? css`
                          left: 7%;
                      `
                    : css`
                          left: 3%;
                      `}
        }

        .WarriorSVG {
            left: 23%;
        }
    }
    &.knight {
        width: ${adaptPx(38)};
        height: ${adaptPx(51)};

        ${LottieWrap} {
            ${(props) =>
                props.$inBattle
                    ? css`
                          width: 170%;
                          height: 110%;
                          left: -10%;
                      `
                    : css`
                          width: 163%;
                          height: 106%;
                          left: -6%;
                      `}
        }

        .WarriorSVG {
            left: 22%;
        }
    }
    &.skeleton {
        width: ${adaptPx(34)};
        height: ${adaptPx(53)};

        ${LottieWrap} {
            left: -28%;

            ${(props) =>
                props.$inBattle
                    ? css`
                          width: 184%;
                          height: 106%;
                      `
                    : css`
                          width: 180%;
                          height: 104%;
                      `}

            .flying_weapon {
                top: ${adaptPx(22)};
                right: ${adaptPx(0)};
                width: ${adaptPx(14)};
                height: ${adaptPx(14)};
            }
        }

        .WarriorSVG {
            left: 20%;
        }
    }
    &.wizard {
        width: ${adaptPx(40)};
        height: ${adaptPx(55)};

        ${LottieWrap} {
            ${(props) =>
                props.$inBattle
                    ? css`
                          width: 162%;
                          height: 107%;
                          left: -15%;
                      `
                    : css`
                          width: 128%;
                          height: 104%;
                      `}

            .flying_weapon {
                top: ${adaptPx(10)};
                right: ${adaptPx(0)};
                width: ${adaptPx(15)};
                height: ${adaptPx(15)};
            }
        }

        .WarriorSVG {
            left: 16%;
        }
    }
`
