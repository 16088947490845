import styled, { createGlobalStyle } from "styled-components"
import bgGame from "./images/bgGame.svg"
import bgMenu from "./images/bgMenu.svg"

export const Background = styled.div<{ $isInGame: boolean }>`
    width: 100%;
    height: 100%;
    background: url(${(props) => (props.$isInGame ? bgGame : bgMenu)}) no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
`

export const GlobalWarriorsStyles = createGlobalStyle`
    .wizard.our svg {
        .wizardHat {
            fill: ${(props) => props.theme.ourWarriors.wizardHat};
        }
        .wizardCoat {
            fill: ${(props) => props.theme.ourWarriors.wizardCoat};
        }
        .wizardRightArm {
            fill: ${(props) => props.theme.ourWarriors.wizardRightArm};
            stroke: ${(props) => props.theme.ourWarriors.wizardRightArm};
        }
        .wizardBuckle {
            fill: ${(props) => props.theme.ourWarriors.wizardBuckle};
        }
        .wizardCloak {
            fill: ${(props) => props.theme.ourWarriors.wizardCloak};
        }
        .wizardLeftArm {
            fill: ${(props) => props.theme.ourWarriors.wizardLeftArm};
            stroke: ${(props) => props.theme.ourWarriors.wizardLeftArm};
        }
        .wizardWand {
            fill: ${(props) => props.theme.ourWarriors.wizardWand};
        }
        .wizardWandOuter {
            fill: ${(props) => props.theme.ourWarriors.wizardWandOuter};
        }
        .wizardWandInner {
            fill: ${(props) => props.theme.ourWarriors.wizardWandInner};
        }
    }

    .wizard.their svg {
        .wizardHat {
            fill: ${(props) => props.theme.theirWarriors.wizardHat};
        }
        .wizardCoat {
            fill: ${(props) => props.theme.theirWarriors.wizardCoat};
        }
        .wizardRightArm {
            fill: ${(props) => props.theme.theirWarriors.wizardRightArm};
            stroke: ${(props) => props.theme.theirWarriors.wizardRightArm};
        }
        .wizardBuckle {
            fill: ${(props) => props.theme.theirWarriors.wizardBuckle};
        }
        .wizardCloak {
            fill: ${(props) => props.theme.theirWarriors.wizardCloak};
        }
        .wizardLeftArm {
            fill: ${(props) => props.theme.theirWarriors.wizardLeftArm};
            stroke: ${(props) => props.theme.theirWarriors.wizardLeftArm};
        }
        .wizardWand {
            fill: ${(props) => props.theme.theirWarriors.wizardWand};
        }
        .wizardWandOuter {
            fill: ${(props) => props.theme.theirWarriors.wizardWandOuter};
        }
        .wizardWandInner {
            fill: ${(props) => props.theme.theirWarriors.wizardWandInner};
        }
    }

    .fairy.our svg {
        .fairyHair {
            fill: ${(props) => props.theme.ourWarriors.fairyHair};
        }
        .fairySkirt {
            fill: ${(props) => props.theme.ourWarriors.fairySkirt};
        }
        .fairyBallOuter {
            fill: ${(props) => props.theme.ourWarriors.fairyBallOuter};
        }
        .fairyBallInner {
            fill: ${(props) => props.theme.ourWarriors.fairyBallInner};
        }
    }
    .fairy.their svg {
        .fairyHair {
            fill: ${(props) => props.theme.theirWarriors.fairyHair};
        }
        .fairySkirt {
            fill: ${(props) => props.theme.theirWarriors.fairySkirt};
        }
        .fairyBallOuter {
            fill: ${(props) => props.theme.theirWarriors.fairyBallOuter};
        }
        .fairyBallInner {
            fill: ${(props) => props.theme.theirWarriors.fairyBallInner};
        }
    }

    .dwarf.our svg {
        .dwarfBelt {
            fill: ${(props) => props.theme.ourWarriors.dwarfBelt};
        }
        .dwarfBuckle {
            fill: ${(props) => props.theme.ourWarriors.dwarfBuckle};
        }
        .dwarfPants {
            fill: ${(props) => props.theme.ourWarriors.dwarfPants};
        }
        .dwarfRightLeg {
            fill: ${(props) => props.theme.ourWarriors.dwarfRightLeg};
        }
        .dwarfAxeHandle {
            fill: ${(props) => props.theme.ourWarriors.dwarfAxeHandle};
        }
        .dwarfAxeHilt {
            fill: ${(props) => props.theme.ourWarriors.dwarfAxeHilt};
        }
    }
    .dwarf.their svg {
        .dwarfBelt {
            fill: ${(props) => props.theme.theirWarriors.dwarfBelt};
        }
        .dwarfBuckle {
            fill: ${(props) => props.theme.theirWarriors.dwarfBuckle};
        }
        .dwarfPants {
            fill: ${(props) => props.theme.theirWarriors.dwarfPants};
        }
        .dwarfRightLeg {
            fill: ${(props) => props.theme.theirWarriors.dwarfRightLeg};
        }
        .dwarfAxeHandle {
            fill: ${(props) => props.theme.theirWarriors.dwarfAxeHandle};
        }
        .dwarfAxeHilt {
            fill: ${(props) => props.theme.theirWarriors.dwarfAxeHilt};
        }
    }

    .skeleton.our svg {
        .skeletonHelmetOuter {
            fill: ${(props) => props.theme.ourWarriors.skeletonHelmetOuter};
        }
        .skeletonHelmetInner {
            fill: ${(props) => props.theme.ourWarriors.skeletonHelmetInner};
        }
        .skeletonPants {
            fill: ${(props) => props.theme.ourWarriors.skeletonPants};
        }
        .skeletonRightLeg {
            fill: ${(props) => props.theme.ourWarriors.skeletonRightLeg};
        }
        .skeletonBallOuter {
            fill: ${(props) => props.theme.ourWarriors.skeletonBallOuter};
        }
        .skeletonBallInner {
            fill: ${(props) => props.theme.ourWarriors.skeletonBallInner};
        }
    }
    .skeleton.their svg {
        .skeletonHelmetOuter {
            fill: ${(props) => props.theme.theirWarriors.skeletonHelmetOuter};
        }
        .skeletonHelmetInner {
            fill: ${(props) => props.theme.theirWarriors.skeletonHelmetInner};
        }
        .skeletonPants {
            fill: ${(props) => props.theme.theirWarriors.skeletonPants};
        }
        .skeletonRightLeg {
            fill: ${(props) => props.theme.theirWarriors.skeletonRightLeg};
        }
        .skeletonBallOuter {
            fill: ${(props) => props.theme.theirWarriors.skeletonBallOuter};
        }
        .skeletonBallInner {
            fill: ${(props) => props.theme.theirWarriors.skeletonBallInner};
        }
    }

    .knight {
        .knightShield {
            &.turquoise,
            &.yellow,
            &.pink,
            &.green,
            &.darkBlue,
            &.lightBlue {
                visibility: hidden;
            }
        }

        &.our svg {
            .knightHelmet {
                fill: ${(props) => props.theme.ourWarriors.knightHelmet};
            }
            .knightVisor {
                fill: ${(props) => props.theme.ourWarriors.knightVisor};
            }
            .knightArmor {
                fill: ${(props) => props.theme.ourWarriors.knightArmor};
            }
            .knightLeftShoulder {
                fill: ${(props) => props.theme.ourWarriors.knightLeftShoulder};
            }
            .knightChest {
                fill: ${(props) => props.theme.ourWarriors.knightChest};
            }
            .knightBelt {
                fill: ${(props) => props.theme.ourWarriors.knightBelt};
            }
            .knightBuckle {
                fill: ${(props) => props.theme.ourWarriors.knightBuckle};
            }
            .knightHilt {
                fill: ${(props) => props.theme.ourWarriors.knightHilt};
            }
            .knightHiltCircle {
                fill: ${(props) => props.theme.ourWarriors.knightHiltCircle};
            }
            .knightShield.${(props) => props.theme.ourColor} {
                visibility: visible;
            }
        }
        &.their svg {
            .knightHelmet {
                fill: ${(props) => props.theme.theirWarriors.knightHelmet};
            }
            .knightVisor {
                fill: ${(props) => props.theme.theirWarriors.knightVisor};
            }
            .knightArmor {
                fill: ${(props) => props.theme.theirWarriors.knightArmor};
            }
            .knightLeftShoulder {
                fill: ${(props) => props.theme.theirWarriors.knightLeftShoulder};
            }
            .knightChest {
                fill: ${(props) => props.theme.theirWarriors.knightChest};
            }
            .knightBelt {
                fill: ${(props) => props.theme.theirWarriors.knightBelt};
            }
            .knightBuckle {
                fill: ${(props) => props.theme.theirWarriors.knightBuckle};
            }
            .knightHilt {
                fill: ${(props) => props.theme.theirWarriors.knightHilt};
            }
            .knightHiltCircle {
                fill: ${(props) => props.theme.theirWarriors.knightHiltCircle};
            }
            .knightShield.${(props) => props.theme.theirColor} {
                visibility: visible;
            }
        }
    }
`
