import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "config/store"
import * as duck from "./duck"
import * as lobbyDuck from "features/lobby/duck"
import { COLOR_OBJECTS } from "shared/constants/colorObjects"
import { ColorSelectorWrap, ColorShield, ShieldsRaw, BlockIcon } from "./elements"

export const ColorSelector = ({ toggleView }) => {
    const dispatch = useAppDispatch()
    const opponent = useSelector(lobbyDuck.selectors.selectOpponent)
    const opponentColor = useSelector(lobbyDuck.selectors.selectOpponentColor)

    const shieldClickHandler = (e) => {
        if (e.target.id === "blocked") return
        const index = e.target.id.split("_")[1]
        dispatch(duck.actions.setColor(COLOR_OBJECTS[index]))
        toggleView()
    }

    const isOpponentColor = (i) => opponentColor === COLOR_OBJECTS[i].name

    useEffect(() => {
        if (!opponent) {
            toggleView()
        }
    }, [opponent, toggleView])

    return (
        <ColorSelectorWrap>
            <ShieldsRaw>
                {[0, 1, 2].map((i) => (
                    <ColorShield
                        key={COLOR_OBJECTS[i].name}
                        className={isOpponentColor(i) ? "blocked" : ""}
                        id={isOpponentColor(i) ? "blocked" : `shield_${i}`}
                        $index={i}
                        onClick={shieldClickHandler}
                    >
                        {isOpponentColor(i) ? (
                            <>
                                <div className="hoverShield" />
                                <BlockIcon />
                            </>
                        ) : null}
                    </ColorShield>
                ))}
            </ShieldsRaw>
            <ShieldsRaw>
                {[3, 4, 5].map((i) => (
                    <ColorShield
                        key={COLOR_OBJECTS[i].name}
                        className={isOpponentColor(i) ? "blocked" : ""}
                        id={isOpponentColor(i) ? "blocked" : `shield_${i}`}
                        $index={i}
                        onClick={shieldClickHandler}
                    >
                        {isOpponentColor(i) ? <BlockIcon /> : null}
                    </ColorShield>
                ))}
            </ShieldsRaw>
        </ColorSelectorWrap>
    )
}
