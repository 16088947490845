import { useSelector } from "react-redux"
import { useAppDispatch } from "config/store"
import { Stars } from "features/shared/stars"
import * as duck from "./duck"
import * as lobbyDuck from "features/lobby/duck"
import * as appDuck from "features/app/duck"
import { HeaderWrap, ExitButton, MusicButton, SoundButton, StarsWrap, RightPart, ButtonWrap, Hover } from "./elements"

export const Header = ({ canExitFromGame = false, pageId = undefined, stars = false }) => {
    const dispatch = useAppDispatch()
    const exitPath = useSelector(appDuck.selectors.selectExitPath)
    const readyToPlay = useSelector(lobbyDuck.selectors.selectReadyToPlay)
    const isMusicOn = useSelector(duck.selectors.selectIsMusicOn)
    const isSoundOn = useSelector(duck.selectors.selectIsSoundOn)

    const exitClickHandler = () => {
        canExitFromGame
            ? dispatch(appDuck.actions.exitFromGame(exitPath))
            : dispatch(appDuck.actions.togglePage(pageId)) // TODO: переименовать pageId
    }

    const soundClickHandler = () => {
        dispatch(duck.actions.toggleSound())
    }

    const musicClickHandler = () => {
        dispatch(duck.actions.toggleMusic())
    }

    return (
        <HeaderWrap>
            <ButtonWrap $isHidden={readyToPlay} onClick={exitClickHandler}>
                <ExitButton />
                <Hover className="hover" />
            </ButtonWrap>
            <RightPart>
                {stars ? (
                    <StarsWrap>
                        <Stars />
                    </StarsWrap>
                ) : (
                    <ButtonWrap onClick={musicClickHandler}>
                        <MusicButton $isMusicOn={isMusicOn} />
                        <Hover className="hover" />
                    </ButtonWrap>
                )}
                <ButtonWrap onClick={soundClickHandler}>
                    <SoundButton $isSoundOn={isSoundOn} />
                    <Hover className="hover" />
                </ButtonWrap>
            </RightPart>
        </HeaderWrap>
    )
}
