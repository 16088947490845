import { ReactComponent as dwarf } from './dwarf.svg'
import { ReactComponent as fairy } from './fairy.svg'
import { ReactComponent as knight } from './knight.svg'
import { ReactComponent as skeleton } from './skeleton.svg'
import { ReactComponent as wizard } from './wizard.svg'

const staticWarriors = {
    dwarf,
    fairy,
    knight,
    skeleton,
    wizard,
}

export { staticWarriors }
