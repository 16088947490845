import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import keyMirror from "utils/keymirror"

const FEATURE_NAME = "header"

const actionTypes = keyMirror(
    {
        TOGGLE_SOUND: null,
        TOGGLE_MUSIC: null,
    },
    FEATURE_NAME
)

const initialState = {
    isSoundOn: true,
    isMusicOn: true,
}

const toggleSound = createAsyncThunk(actionTypes.TOGGLE_SOUND, () => {})

const toggleMusic = createAsyncThunk(actionTypes.TOGGLE_MUSIC, () => {})

const headerSlice = createSlice({
    name: FEATURE_NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(toggleSound.fulfilled, (state) => {
                state.isSoundOn = !state.isSoundOn
            })
            .addCase(toggleMusic.fulfilled, (state) => {
                state.isMusicOn = !state.isMusicOn
            })
    },
    selectors: {
        selectIsSoundOn: (state) => state.isSoundOn,
        selectIsMusicOn: (state) => state.isMusicOn,
    },
})

export const actions = { toggleSound, toggleMusic }
export const selectors = headerSlice.selectors
export default headerSlice.reducer
