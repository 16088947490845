import type { ColorObj } from "features/colorSelector/duck/types"
import shield1 from "shared/images/shields/shield1.svg"
import shield2 from "shared/images/shields/shield2.svg"
import shield3 from "shared/images/shields/shield3.svg"
import shield4 from "shared/images/shields/shield4.svg"
import shield5 from "shared/images/shields/shield5.svg"
import shield6 from "shared/images/shields/shield6.svg"
import shieldDefault from "shared/images/shields/shieldDefault.svg"

export const COLOR_OBJECTS: ColorObj[] = [
    {
        index: 0,
        color: "#29B1B7",
        imageURL: shield1,
        name: "turquoise",
    },
    {
        index: 1,
        color: "#FCB934",
        imageURL: shield2,
        name: "yellow",
    },
    {
        index: 2,
        color: "#AF2C7A",
        imageURL: shield3,
        name: "pink",
    },
    {
        index: 3,
        color: "#44A053",
        imageURL: shield4,
        name: "green",
    },
    {
        index: 4,
        color: "#3F3FBC",
        imageURL: shield5,
        name: "darkBlue",
    },
    {
        index: 5,
        color: "#18A1D1",
        imageURL: shield6,
        name: "lightBlue",
    },
    {
        index: null,
        color: "#6DA6CF",
        imageURL: shieldDefault,
        name: "default",
    },
]
