import { io } from "socket.io-client"
import type { Socket as SocketType } from "socket.io-client"

// TODO: check before deploy
const url = process.env.NODE_ENV === "production" ? "" : "http://localhost:3000"

class Socket {
    socket: SocketType
    listeners: Record<Parameters<SocketType["on"]>[0], Parameters<SocketType["on"]>[1]> = {}

    create = (params) => {
        // console.log("Socket create", params)
        this.socket = io(`${url}${params.namespace}`, {
            autoConnect: false,
        })

        this.socket.auth = {}

        Object.entries(params).forEach(([key, value]) => {
            if (key !== "namespace") {
                this.socket.auth[key] = value
            }
        })
    }

    connect = () => {
        // console.log("Socket connect")
        if (this.socket) this.socket.connect()
        else return new Error("There isn't any socket")
    }

    disconnect = () => {
        // console.log("Socket disconnect")
        if (this.socket) return this.socket.disconnect()
        else return new Error("There isn't any socket")
    }

    addListener = (eventName: Parameters<SocketType["on"]>[0], listener: Parameters<SocketType["on"]>[1]) => {
        // console.log("Socket addListener for event", eventName)
        this.socket.on(eventName, listener)
        this.listeners[eventName] = listener
    }

    removeAllListeners = () => {
        // console.log("Socket removeAllListeners")
        Object.entries(this.listeners).forEach(([eventName, listener]) => {
            this.socket.off(eventName, listener)
        })
        this.listeners = {}
    }

    emitEvent = (eventName: Parameters<SocketType["emit"]>[0], ...args: Parameters<SocketType["emit"]>[1]) => {
        // console.log("Socket emitEvent: eventName, ...args", eventName, ...args)
        this.socket.emit(eventName, ...args)
    }

    emitEventWithAcknolegement = (eventName: Parameters<SocketType["emit"]>[0], arg: Parameters<SocketType["emit"]>[1], cb: (response: string) => void) => {
        // console.log("Socket emitEventWithAcknolegement eventName, arg:", eventName, arg)
        this.socket.emit(eventName, arg, (response: string) => {
            cb(response)
        })
    }

    terminate = () => {
        // console.log("Socket terminate")
        this.removeAllListeners()
        this.socket = undefined
    }
}

export const socket = new Socket()
