type PlaygroundPoint = {
    index: number
    /** calculated distance (offsetLeft) from left edge of points parent (<PointsWrap />) */
    offset: number
}

export const PLAYGROUND_POINTS_AMOUNT = 20

export const playgroundPoints: PlaygroundPoint[] = Array.from({
    length: PLAYGROUND_POINTS_AMOUNT,
}).map((_, i) => ({
    index: i,
    offset: 0,
}))
