import styled from "styled-components"
import { adaptPx } from "utils/style-utils"
const blue = "#56C3EA"
const blueHover = "#78cfee"
const grey = "#d6d6d6"
const selected = "#00508A"
const selectedHover = "#11679d"

export const ButtonS = styled.div<{
    $width?: number
    $disabled: boolean
    $selected: boolean
    $blocked: boolean
}>`
    display: inline-block;
    -webkit-appearance: none;
    font-size: ${adaptPx(24)};
    line-height: ${adaptPx(24)};
    font-weight: 500;
    padding: ${adaptPx(16)} ${adaptPx(20)} ${adaptPx(16)};
    //min-width: ${adaptPx(120)};
    width: ${(props) => (props.$width ? adaptPx(props.$width - 44) : "auto")};
    text-align: center;
    margin: 0;
    background: ${(props) => (props.$disabled ? grey : props.$selected ? selected : blue)};
    color: white;
    border-radius: ${adaptPx(10)};
    cursor: ${(props) => (props.$disabled ? "default" : "pointer")};
    letter-spacing: ${adaptPx(0.5)};
    text-decoration: none;
    transition: background-color 0.2s;
    border: ${adaptPx(2)} solid ${(props) => (props.$selected ? blue : "transparent")};
    //pointer-events: ${(props) => (props.$blocked ? "none" : "auto")};

    &:hover {
        background: ${(props) => (props.$disabled ? grey : props.$selected ? selectedHover : blueHover)};
    }
`
