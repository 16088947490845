import fairy from "./fairy.json"
import dwarf from "./dwarf.json"
import knight from "./knight.json"
import skeleton from "./skeleton.json"
import wizard from "./wizard.json"
import { ReactComponent as skeletonFlyingWeapon } from './skeleton_weapon.svg'
import { ReactComponent as wizardFlyingWeapon } from './wizard_weapon.svg'

export const fightAnimations = {
    fairy,
    dwarf,
    knight,
    skeleton,
    wizard,
}


export const flyingWeapons = {
    skeleton: skeletonFlyingWeapon,
    wizard: wizardFlyingWeapon,
}
