import { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import Lottie from "lottie-react"
import { useAppDispatch } from "config/store"
import type { OurWarrior, TheirWarrior } from "features/battlefield/duck/types"
import * as battlefieldDuck from "features/battlefield/duck"
import { PLAYGROUND_POINTS_AMOUNT, playgroundPoints } from "shared/constants/playgroundPoints"
import { fightAnimations, flyingWeapons } from "./animations/fight"
import { walkAnimations } from "./animations/walk"
import { staticWarriors } from "shared/images/warriors"
import {
    START_WALKING_ANIMATION_DURATION_MS,
    STOP_WALKING_ANIMATION_DURATION_MS,
    STATS_HEALTH,
    KILLED_DISPLAY_MS,
    HURT_DISPLAY_MS,
} from "shared/constants/gameplayConstants"
import { WarriorWrap, LottieWrap, Health } from "./elements"

type Props = {
    warrior: OurWarrior | TheirWarrior
    currentHealth: number
}

export const Warrior = ({ warrior, currentHealth }: Props) => {
    const dispatch = useAppDispatch()
    const ourWarriors = useSelector(battlefieldDuck.selectors.selectOurWarriors)
    const theirWarriors = useSelector(battlefieldDuck.selectors.selectTheirWarriors)
    let fightAnimationOptions
    let walkAnimationOptions
    const walkingRef = useRef(null)

    const [isHurt, setIsHurt] = useState(false)

    if (warrior.inBattle) {
        fightAnimationOptions = {
            loop: true,
            autoplay: true,
            animationData: fightAnimations[warrior.type],
        }
    }
    if (warrior.walkStatus) {
        walkAnimationOptions = {
            loop: warrior.walkStatus === "walk",
            autoplay: true,
            animationData: walkAnimations[warrior.type][warrior.walkStatus],
        }
    }

    useEffect(() => {
        if (walkingRef.current) {
            if (warrior.walkStatus === "walk") {
                walkingRef.current.setSpeed(1)
            } else if (warrior.walkStatus === "start" || warrior.walkStatus === "stop") {
                const duration = walkingRef.current.getDuration()
                const needDuration =
                    (warrior.walkStatus === "start"
                        ? START_WALKING_ANIMATION_DURATION_MS
                        : STOP_WALKING_ANIMATION_DURATION_MS) / 1000
                walkingRef.current.setSpeed(duration / needDuration)
            }
        }
    }, [warrior.walkStatus])

    useEffect(() => {
        if (warrior.opposite === false && warrior.shouldMove) {
            if (warrior.position === 0) {
                // If move instantly, css transtion from 0 to 1 point won't be applied
                setTimeout(() => {
                    dispatch(battlefieldDuck.actions.moveWarrior(warrior.key))
                }, 50)
            } else {
                dispatch(battlefieldDuck.actions.moveWarrior(warrior.key))
            }
        }
    }, [dispatch, warrior.key, warrior.opposite, warrior.position, warrior.shouldMove])

    useEffect(() => {
        if (warrior.opposite === false && warrior.shouldAttack) {
            dispatch(battlefieldDuck.actions.attack(warrior.key))
        }
    }, [dispatch, warrior.key, warrior.opposite, warrior.shouldAttack])

    useEffect(() => {
        if (!warrior.isKilled && warrior.currentHealth === 0) {
            if (warrior.opposite) {
                dispatch(battlefieldDuck.actions.killOpponentWarrior(warrior.key))
            } else {
                dispatch(battlefieldDuck.actions.killWarrior(warrior.key))
            }
            setTimeout(() => {
                if (warrior.opposite) {
                    dispatch(battlefieldDuck.actions.removeOpponentWarrior(warrior.key))
                } else {
                    dispatch(battlefieldDuck.actions.removeWarrior(warrior.key))
                }
            }, KILLED_DISPLAY_MS)
        }
    }, [dispatch, warrior.key, warrior.currentHealth, warrior.opposite, warrior.isKilled])

    useEffect(() => {
        if (!warrior.isKilled && warrior.currentHealth < warrior.stats.health * STATS_HEALTH) {
            setIsHurt(true)
            setTimeout(() => {
                setIsHurt(false)
            }, HURT_DISPLAY_MS)
        }
    }, [dispatch, warrior.currentHealth, warrior.isKilled, warrior.stats.health])

    const StaticWarrior = staticWarriors[warrior.type]
    const FlyingWeapon = flyingWeapons[warrior.type]

    let weaponFlyDistance: number
    if (warrior.isShooting) {
        if (warrior.enemyKey === "tower") {
            weaponFlyDistance = warrior.opposite
                ? playgroundPoints[warrior.position].offset - playgroundPoints[0].offset
                : playgroundPoints[PLAYGROUND_POINTS_AMOUNT - 1].offset - playgroundPoints[warrior.position].offset
        } else {
            const enemy = warrior.opposite ? ourWarriors[warrior.enemyKey] : theirWarriors[warrior.enemyKey]
            if (enemy) {
                weaponFlyDistance = warrior.opposite
                    ? playgroundPoints[warrior.position].offset - playgroundPoints[enemy.position].offset
                    : playgroundPoints[enemy.position].offset - playgroundPoints[warrior.position].offset
            }
        }
    }

    return (
        <WarriorWrap
            className={`${warrior.type} ${warrior.opposite ? "their" : "our"}`}
            $opposite={warrior.opposite}
            $left={playgroundPoints[warrior.position].offset}
            $transitionTime={warrior.stats.pace / 1000}
            $inBattle={warrior.inBattle}
            $isHurt={isHurt}
            $isKilled={warrior.isKilled}
        >
            <Health $healthKoeff={currentHealth / (warrior.stats.health * STATS_HEALTH)} />
            {warrior.inBattle ? (
                <LottieWrap $isShooting={warrior.isShooting} $weaponFlyDistance={weaponFlyDistance}>
                    <Lottie {...fightAnimationOptions} />
                    {FlyingWeapon && <FlyingWeapon className="flying_weapon" />}
                </LottieWrap>
            ) : warrior.walkStatus ? (
                <LottieWrap>
                    <Lottie {...walkAnimationOptions} lottieRef={walkingRef} />
                </LottieWrap>
            ) : (
                <StaticWarrior className="WarriorSVG" />
            )}
        </WarriorWrap>
    )
}
