import fairy from "./fairy.json"
import dwarf from "./dwarf.json"
import knight from "./knight.json"
import wizard from "./wizard.json"
import skeleton from "./skeleton.json"

export const avatarAnimations = {
    fairy,
    dwarf,
    knight,
    skeleton,
    wizard,
}
