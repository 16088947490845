import type { WarriorTemplate } from "features/warriorsSelector/duck/types"
import { staticWarriors } from "shared/images/warriors"
import { ReactComponent as StatsIcons } from "../../images/stats.svg"
import { Avatar, WarriorStats, WarriorCardWrap, StatText } from "./elements"

type Props = {
    warrior: WarriorTemplate
    isSelected: boolean
    isDisabled: boolean
    warriorClickHandler: React.MouseEventHandler<HTMLDivElement>
}
export const WarriorCard = ({ warrior, isSelected, isDisabled, warriorClickHandler }: Props) => {
    const AvatarSVG = staticWarriors[warrior.type]
    return (
        <WarriorCardWrap
            id={warrior.type}
            key={warrior.type}
            $isSelected={isSelected}
            $isDisabled={isDisabled}
            $price={warrior.price}
            onClick={isDisabled ? () => null : warriorClickHandler}
        >
            <WarriorStats>
                <StatsIcons className="icons" />
                <StatText>{warrior.stats.health}</StatText>
                <StatText>{warrior.stats.attack}</StatText>
                <StatText>{warrior.stats.speed}</StatText>
            </WarriorStats>
            <Avatar className={`${warrior.type} our`}>
                <AvatarSVG className="AvatarSVG" />
            </Avatar>
        </WarriorCardWrap>
    )
}
