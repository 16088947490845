import styled from "styled-components"
import { adaptPx } from "utils/style-utils"
import check from "./images/check.svg"
import leftMenu from "./images/lobby_left_side.svg"
import rightMenu from "./images/lobby_right_side.svg"
import link from "./images/gameLink.svg"
import type { ColorObj } from "features/colorSelector/duck/types"

export const LobbyWrap = styled.div`
    width: 100%;
    height: ${adaptPx(445)};
    min-height: ${adaptPx(445)};
    display: flex;
    justify-content: center;
`

export const PlayerMenu = styled.div`
    width: ${adaptPx(290)};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`

export const LeftPlayerMenu = styled(PlayerMenu)`
    //background: white;
    background: url(${leftMenu}) center/contain no-repeat;
    border-bottom-left-radius: ${adaptPx(20)};
    border-top-left-radius: ${adaptPx(20)};
    padding-right: ${adaptPx(40)};

    .playButton {
        font-size: ${adaptPx(20)};
        line-height: ${adaptPx(14)};
        width: ${adaptPx(160)};
    }
`

export const RightPlayerMenu = styled(PlayerMenu)`
    //background: #00508a;
    background: url(${rightMenu}) center/contain no-repeat;
    background-position-x: right;
    border-bottom-right-radius: ${adaptPx(20)};
    border-top-right-radius: ${adaptPx(20)};
    padding-left: ${adaptPx(40)};
`

export const Title = styled.div<{ $side: string }>`
    font-size: ${adaptPx(24)};
    line-height: ${adaptPx(33)};
    text-align: center;
    color: ${(props) => (props.$side === "left" ? "#00508A" : "#FFFFFF")};
`

export const ChosenColorWrap = styled.div`
    border-radius: ${adaptPx(10)};
    width: ${adaptPx(200)};
    overflow: hidden;
`

export const ShieldWrap = styled.div<{ $colorObj: ColorObj }>`
    width: 100%;
    height: ${adaptPx(200)};
    background-image: url(${(props) => props.$colorObj.imageURL});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    background-color: ${(props) => props.$colorObj.color};
`

export const ChangeColorButton = styled.div`
    width: 100%;
    height: ${adaptPx(40)};
    box-sizing: border-box;
    background: #ffffff;
    color: #00508a;
    font-size: ${adaptPx(20)};
    line-height: ${adaptPx(30)};
    text-align: center;
    border: ${adaptPx(2)} solid #56c3ea;
    border-bottom-right-radius: ${adaptPx(10)};
    border-bottom-left-radius: ${adaptPx(10)};
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
        background: #ddf3fb;
    }
`

export const CheckMark = styled.div`
    width: ${adaptPx(55)};
    height: ${adaptPx(55)};
    background: url(${check}) center/contain no-repeat;
`

export const GameLinkWrap = styled.div`
    display: flex;
    align-items: center;
`

export const GameLinkIcon = styled.div`
    width: ${adaptPx(55)};
    height: ${adaptPx(55)};
    border-radius: 50%;
    background-color: #56c3ea;
    background: url(${link}) center/contain no-repeat;
    cursor: pointer;
    &:hover {
        background-color: #75ceee;
    }
`

export const GameLinkText = styled.div`
    font-size: ${adaptPx(20)};
    line-height: ${adaptPx(23)};
    color: white;
    white-space: pre-wrap;
    margin-left: ${adaptPx(15)};
`

export const OpponentStatusWrap = styled(GameLinkWrap)``

export const OpponentStatusText = styled.div`
    font-size: ${adaptPx(20)};
    line-height: ${adaptPx(23)};
    color: white;
`
