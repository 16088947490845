export const STATS_ATTACK = 1
export const STATS_HEALTH = 1
export const START_WALKING_ANIMATION_DURATION_MS = 200
export const STOP_WALKING_ANIMATION_DURATION_MS = 300
export const ATTACK_COOLDOWN_MS = 1000
export const WEAPON_FLIGHT_DURATION_MS = 300
export const MAX_COINS_AMOUNT = 100
export const COINS_INCREASE_DELAY_MS = 5000
export const HURT_DISPLAY_MS = 200
export const KILLED_DISPLAY_MS = 3000
