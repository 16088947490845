import styled from "styled-components"
import { adaptPx } from "utils/style-utils"

export const PlaygroundWrap = styled.div`
    position: absolute;
    bottom: ${adaptPx(30)};
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
`

export const PointsWrap = styled.div`
    position: absolute;
    width: 86.625%;
    display: flex;
    justify-content: space-between;
`

export const Point = styled.div`
    width: ${adaptPx(5)};
    height: ${adaptPx(5)};
    background: black;
`

export const WarriorsWrap = styled.div`
    position: absolute;
    width: 86.625%;
`
