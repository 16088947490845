import styled from "styled-components"
import { adaptPx } from "utils/style-utils"

export const WarriorsPageWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    top: 0;
    left: 0;
`

export const Title = styled.div`
    font-weight: bold;
    font-size: ${adaptPx(32)};
    line-height: ${adaptPx(44)};
    color: white;
`
