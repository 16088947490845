import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "config/store"
import * as duck from "./duck"
import * as colorSelectorDuck from "features/colorSelector/duck"
import * as appDuck from "features/app/duck"
import * as socketDuck from "features/socket/duck"
import { COLOR_OBJECTS } from "shared/constants/colorObjects"
import { Button } from "shared/ui/components/button"
import * as clipboard from "clipboard-polyfill"

import {
    LobbyWrap,
    LeftPlayerMenu,
    RightPlayerMenu,
    Title,
    ChangeColorButton,
    ChosenColorWrap,
    ShieldWrap,
    CheckMark,
    GameLinkIcon,
    GameLinkText,
    GameLinkWrap,
    OpponentStatusText,
    OpponentStatusWrap,
} from "./elements"

const TEXTS = {
    noPlayer: "Нет соперника",
    chooseColor: "Выбрать цвет",
    changeColor: "Сменить цвет",
    toPlay: "Играть",
    gameLink: "Ссылка\nна игру",
    isPreparing: "Готовится к игре...",
    waiting: "Ожидание",
}
const defaultColorObj = COLOR_OBJECTS[COLOR_OBJECTS.length - 1]

export const Lobby = ({ toggleView }) => {
    const dispatch = useAppDispatch()
    const [isLinkCopied, setIsLinkCopied] = useState(false)
    const opponent = useSelector(duck.selectors.selectOpponent)
    const gameLink = useSelector(duck.selectors.selectGameLink)
    const readyToPlay = useSelector(duck.selectors.selectReadyToPlay)
    const colorObj = useSelector(colorSelectorDuck.selectors.selectColorObj)
    const isSocketInitialized = useSelector(socketDuck.selectors.selectIsSocketInitialized)
    const isSocketConnected = useSelector(socketDuck.selectors.selectIsSocketConnected)
    const areSocketListenersRegistered = useSelector(socketDuck.selectors.selectAreSocketListenersRegisteres)
    const userName = useSelector(socketDuck.selectors.selectUserName)

    const onPlayClickHandler = () => {
        dispatch(duck.actions.setReadyToPlay(true))
    }

    const onChangeColorClickHandler = () => {
        toggleView()
    }

    const onLinkClickHandler = () => {
        clipboard.writeText(gameLink)
        setIsLinkCopied(true)
    }

    useEffect(() => {
        if (!opponent) {
            if (!isSocketInitialized) {
                dispatch(socketDuck.actions.initializeSocket())
            } else if (!isSocketConnected) {
                dispatch(socketDuck.actions.connectSocket())
            } else if (!areSocketListenersRegistered) {
                dispatch(socketDuck.actions.registerSocketListeners("lobby"))
            }
        }
    }, [dispatch, opponent, isSocketInitialized, isSocketConnected, areSocketListenersRegistered])

    useEffect(() => {
        if (readyToPlay && opponent?.isReady) {
            // to make sure ready event was sent
            setTimeout(() => {
                dispatch(socketDuck.actions.disconnectSocket())
                dispatch(socketDuck.actions.terminateSocket())
                dispatch(socketDuck.actions.setIsOpponentConnected(false))
                dispatch(appDuck.actions.togglePage("game"))
            }, 100)
        }
    }, [dispatch, readyToPlay, opponent?.isReady])

    return (
        <LobbyWrap>
            <LeftPlayerMenu>
                <Title $side="left">{userName}</Title>
                <ChosenColorWrap>
                    <ShieldWrap $colorObj={colorObj} />
                    {opponent && !readyToPlay && (
                        <ChangeColorButton onClick={onChangeColorClickHandler}>
                            {colorObj.name === "default" ? TEXTS.chooseColor : TEXTS.changeColor}
                        </ChangeColorButton>
                    )}
                </ChosenColorWrap>
                {readyToPlay ? (
                    <CheckMark />
                ) : (
                    <Button
                        className="playButton"
                        text={TEXTS.toPlay}
                        onClick={onPlayClickHandler}
                        disabled={!opponent || colorObj.name === "default"}
                    />
                )}
            </LeftPlayerMenu>

            <RightPlayerMenu>
                <Title $side="right">{opponent ? opponent.name : TEXTS.noPlayer}</Title>
                <ChosenColorWrap>
                    <ShieldWrap $colorObj={opponent ? opponent.colorObj : defaultColorObj} />
                </ChosenColorWrap>
                {opponent ? (
                    <OpponentStatusWrap>
                        {opponent.isReady ? (
                            <CheckMark />
                        ) : (
                            <OpponentStatusText>{readyToPlay ? TEXTS.waiting : TEXTS.isPreparing}</OpponentStatusText>
                        )}
                    </OpponentStatusWrap>
                ) : (
                    <GameLinkWrap>
                        {isLinkCopied ? <CheckMark /> : <GameLinkIcon onClick={onLinkClickHandler} />}
                        <GameLinkText>{TEXTS.gameLink}</GameLinkText>
                    </GameLinkWrap>
                )}
            </RightPlayerMenu>
        </LobbyWrap>
    )
}
