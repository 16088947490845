import type { ColorObj } from "features/colorSelector/duck/types"

type HEXColor = `#${string}`

type CastleColors = {
    color1: HEXColor
    color2: HEXColor
    color3: HEXColor
    color4: HEXColor
    color5: HEXColor
    color6: HEXColor
    color7: HEXColor
    color8: HEXColor
    color9: HEXColor
}

type WarriorSelectorColors = {
    bg: HEXColor
    card: HEXColor
}

type WarriorColors = {
    wizardHat: HEXColor
    wizardCoat: HEXColor
    wizardRightArm: HEXColor
    wizardBuckle: HEXColor
    wizardCloak: HEXColor
    wizardLeftArm: HEXColor
    wizardWand: HEXColor
    wizardWandOuter: HEXColor
    wizardWandInner: HEXColor

    fairyHair: HEXColor
    fairySkirt: HEXColor
    fairyBallOuter: HEXColor
    fairyBallInner: HEXColor

    dwarfBelt: HEXColor
    dwarfBuckle: HEXColor
    dwarfPants: HEXColor
    dwarfRightLeg: HEXColor
    dwarfAxeHandle: HEXColor
    dwarfAxeHilt: HEXColor

    skeletonHelmetOuter: HEXColor
    skeletonHelmetInner: HEXColor
    skeletonPants: HEXColor
    skeletonRightLeg: HEXColor
    skeletonBallOuter: HEXColor
    skeletonBallInner: HEXColor

    knightHelmet: HEXColor
    knightVisor: HEXColor
    knightArmor: HEXColor
    knightLeftShoulder: HEXColor
    knightChest: HEXColor
    knightBelt: HEXColor
    knightBuckle: HEXColor
    knightHilt: HEXColor
    knightHiltCircle: HEXColor
}

const warriorsColors: { [key in ColorObj["name"]]: WarriorColors } & { default: WarriorColors } = {
    pink: {
        wizardHat: "#AF2C7A",
        wizardCoat: "#AF2C7A",
        wizardRightArm: "#AF2C7A",
        wizardBuckle: "#AF2C7A",
        wizardCloak: "#840D62",
        wizardLeftArm: "#E85DB0",
        wizardWand: "#840D62",
        wizardWandOuter: "#AF2C7A",
        wizardWandInner: "#840D62",
        fairyHair: "#AF2C7A",
        fairySkirt: "#AF2C7A",
        fairyBallOuter: "#AF2C7A",
        fairyBallInner: "#840D62",
        dwarfBelt: "#840D62",
        dwarfBuckle: "#AF2C7A",
        dwarfPants: "#AF2C7A",
        dwarfRightLeg: "#840D62",
        dwarfAxeHandle: "#840D62",
        dwarfAxeHilt: "#AF2C7A",
        skeletonHelmetOuter: "#AF2C7A",
        skeletonHelmetInner: "#840D62",
        skeletonPants: "#AF2C7A",
        skeletonRightLeg: "#840D62",
        skeletonBallOuter: "#AF2C7A",
        skeletonBallInner: "#840D62",
        knightHelmet: "#840D62",
        knightVisor: "#AF2C7A",
        knightArmor: "#840D62",
        knightLeftShoulder: "#AF2C7A",
        knightChest: "#AF2C7A",
        knightBelt: "#840D62",
        knightBuckle: "#AF2C7A",
        knightHilt: "#840D62",
        knightHiltCircle: "#D33597",
    },
    yellow: {
        wizardHat: "#E5602C",
        wizardCoat: "#F48E3D",
        wizardRightArm: "#F48E3D",
        wizardBuckle: "#F48E3D",
        wizardCloak: "#DD4319",
        wizardLeftArm: "#F9A826",
        wizardWand: "#DD4319",
        wizardWandOuter: "#F48E3D",
        wizardWandInner: "#DD4319",
        fairyHair: "#DD4319",
        fairySkirt: "#DD4319",
        fairyBallOuter: "#F48E3D",
        fairyBallInner: "#DD4319",
        dwarfBelt: "#E5602C",
        dwarfBuckle: "#F48E3D",
        dwarfPants: "#F48E3D",
        dwarfRightLeg: "#F48E3D",
        dwarfAxeHandle: "#E5602C",
        dwarfAxeHilt: "#F48E3D",
        skeletonHelmetOuter: "#E5602C",
        skeletonHelmetInner: "#DD4319",
        skeletonPants: "#E5602C",
        skeletonRightLeg: "#DD4319",
        skeletonBallOuter: "#F48E3D",
        skeletonBallInner: "#DD4319",
        knightHelmet: "#E5602C",
        knightVisor: "#F48E3D",
        knightArmor: "#DD4319",
        knightLeftShoulder: "#F48E3D",
        knightChest: "#F48E3D",
        knightBelt: "#DD4319",
        knightBuckle: "#F48E3D",
        knightHilt: "#DD4319",
        knightHiltCircle: "#FCB934",
    },
    green: {
        wizardHat: "#44A053",
        wizardCoat: "#44A053",
        wizardRightArm: "#44A053",
        wizardBuckle: "#44A053",
        wizardCloak: "#317C4A",
        wizardLeftArm: "#6DC65D",
        wizardWand: "#317C4A",
        wizardWandOuter: "#44A053",
        wizardWandInner: "#317C4A",
        fairyHair: "#317C4A",
        fairySkirt: "#317C4A",
        fairyBallOuter: "#44A053",
        fairyBallInner: "#317C4A",
        dwarfBelt: "#317C4A",
        dwarfBuckle: "#44A053",
        dwarfPants: "#44A053",
        dwarfRightLeg: "#317C4A",
        dwarfAxeHandle: "#317C4A",
        dwarfAxeHilt: "#44A053",
        skeletonHelmetOuter: "#44A053",
        skeletonHelmetInner: "#317C4A",
        skeletonPants: "#44A053",
        skeletonRightLeg: "#317C4A",
        skeletonBallOuter: "#44A053",
        skeletonBallInner: "#317C4A",
        knightHelmet: "#317C4A",
        knightVisor: "#44A053",
        knightArmor: "#317C4A",
        knightLeftShoulder: "#44A053",
        knightChest: "#44A053",
        knightBelt: "#317C4A",
        knightBuckle: "#44A053",
        knightHilt: "#317C4A",
        knightHiltCircle: "#6DC65D",
    },
    turquoise: {
        wizardHat: "#29B1B7",
        wizardCoat: "#29B1B7",
        wizardRightArm: "#29B1B7",
        wizardBuckle: "#29B1B7",
        wizardCloak: "#036482",
        wizardLeftArm: "#5BD6D3",
        wizardWand: "#036482",
        wizardWandOuter: "#29B1B7",
        wizardWandInner: "#036482",
        fairyHair: "#036482",
        fairySkirt: "#036482",
        fairyBallOuter: "#29B1B7",
        fairyBallInner: "#036482",
        dwarfBelt: "#036482",
        dwarfBuckle: "#29B1B7",
        dwarfPants: "#29B1B7",
        dwarfRightLeg: "#036482",
        dwarfAxeHandle: "#036482",
        dwarfAxeHilt: "#29B1B7",
        skeletonHelmetOuter: "#29B1B7",
        skeletonHelmetInner: "#036482",
        skeletonPants: "#29B1B7",
        skeletonRightLeg: "#036482",
        skeletonBallOuter: "#29B1B7",
        skeletonBallInner: "#036482",
        knightHelmet: "#036482",
        knightVisor: "#29B1B7",
        knightArmor: "#036482",
        knightLeftShoulder: "#29B1B7",
        knightChest: "#29B1B7",
        knightBelt: "#036482",
        knightBuckle: "#29B1B7",
        knightHilt: "#036482",
        knightHiltCircle: "#29B1B7",
    },
    lightBlue: {
        wizardHat: "#1996CE",
        wizardCoat: "#1996CE",
        wizardRightArm: "#1996CE",
        wizardBuckle: "#1996CE",
        wizardCloak: "#174691",
        wizardLeftArm: "#29C8ED",
        wizardWand: "#174691",
        wizardWandOuter: "#1996CE",
        wizardWandInner: "#174691",
        fairyHair: "#174691",
        fairySkirt: "#174691",
        fairyBallOuter: "#1996CE",
        fairyBallInner: "#174691",
        dwarfBelt: "#174691",
        dwarfBuckle: "#174691",
        dwarfPants: "#1996CE",
        dwarfRightLeg: "#174691",
        dwarfAxeHandle: "#174691",
        dwarfAxeHilt: "#1996CE",
        skeletonHelmetOuter: "#1996CE",
        skeletonHelmetInner: "#174691",
        skeletonPants: "#1996CE",
        skeletonRightLeg: "#174691",
        skeletonBallOuter: "#1996CE",
        skeletonBallInner: "#174691",
        knightHelmet: "#174691",
        knightVisor: "#1996CE",
        knightArmor: "#174691",
        knightLeftShoulder: "#1996CE",
        knightChest: "#1996CE",
        knightBelt: "#174691",
        knightBuckle: "#1996CE",
        knightHilt: "#174691",
        knightHiltCircle: "#1996CE",
    },
    darkBlue: {
        wizardHat: "#06012D",
        wizardCoat: "#262687",
        wizardRightArm: "#262687",
        wizardBuckle: "#262687",
        wizardCloak: "#06012D",
        wizardLeftArm: "#5983CE",
        wizardWand: "#06012D",
        wizardWandOuter: "#0A0A51",
        wizardWandInner: "#06012D",
        fairyHair: "#0A0051",
        fairySkirt: "#0A0051",
        fairyBallOuter: "#262687",
        fairyBallInner: "#0A0051",
        dwarfBelt: "#262687",
        dwarfBuckle: "#06012D",
        dwarfPants: "#06012D",
        dwarfRightLeg: "#0A0051",
        dwarfAxeHandle: "#06012D",
        dwarfAxeHilt: "#5983CE",
        skeletonHelmetOuter: "#0A0051",
        skeletonHelmetInner: "#06012D",
        skeletonPants: "#0A0051",
        skeletonRightLeg: "#06012D",
        skeletonBallOuter: "#262687",
        skeletonBallInner: "#0A0051",
        knightHelmet: "#06012D",
        knightVisor: "#0A0A51",
        knightArmor: "#06012D",
        knightLeftShoulder: "#0A0051",
        knightChest: "#262687",
        knightBelt: "#06012D",
        knightBuckle: "#0A0051",
        knightHilt: "#06012D",
        knightHiltCircle: "#262687",
    },
    // the same as darkBlue, to show on warriorsPage
    default: {
        wizardHat: "#06012D",
        wizardCoat: "#262687",
        wizardRightArm: "#262687",
        wizardBuckle: "#262687",
        wizardCloak: "#06012D",
        wizardLeftArm: "#5983CE",
        wizardWand: "#06012D",
        wizardWandOuter: "#0A0A51",
        wizardWandInner: "#06012D",
        fairyHair: "#0A0051",
        fairySkirt: "#0A0051",
        fairyBallOuter: "#262687",
        fairyBallInner: "#0A0051",
        dwarfBelt: "#262687",
        dwarfBuckle: "#06012D",
        dwarfPants: "#06012D",
        dwarfRightLeg: "#0A0051",
        dwarfAxeHandle: "#06012D",
        dwarfAxeHilt: "#5983CE",
        skeletonHelmetOuter: "#0A0051",
        skeletonHelmetInner: "#06012D",
        skeletonPants: "#0A0051",
        skeletonRightLeg: "#06012D",
        skeletonBallOuter: "#262687",
        skeletonBallInner: "#0A0051",
        knightHelmet: "#06012D",
        knightVisor: "#0A0A51",
        knightArmor: "#06012D",
        knightLeftShoulder: "#0A0051",
        knightChest: "#262687",
        knightBelt: "#06012D",
        knightBuckle: "#0A0051",
        knightHilt: "#06012D",
        knightHiltCircle: "#262687",
    },
}

const castleColors: { [key in ColorObj["name"]]: CastleColors } & { default: null } = {
    turquoise: {
        color1: "#88E2DE",
        color2: "#5BD6D3",
        color3: "#3CC3C6",
        color4: "#29B1B7",
        color5: "#3CC3C6",
        color6: "#098282",
        color7: "#036F72",
        color8: "#098282",
        color9: "#DDFFFC",
    },
    yellow: {
        color1: "#F9D53E",
        color2: "#FCB934",
        color3: "#F9A826",
        color4: "#F48E3D",
        color5: "#F48E3D",
        color6: "#599B3A",
        color7: "#036F72",
        color8: "#599B3A",
        color9: "#FFF8D9",
    },
    pink: {
        color1: "#F487C8",
        color2: "#E85DB0",
        color3: "#D33597",
        color4: "#AF2C7A",
        color5: "#CE4292",
        color6: "#0B9E97",
        color7: "#036F72",
        color8: "#098282",
        // TODO: change
        color9: "#DDFFFC"
    },
    green: {
        color1: "#8DD35F",
        color2: "#6DC65D",
        color3: "#58B753",
        color4: "#44A053",
        color5: "#6DC65D",
        color6: "#0A7F69",
        color7: "#036F72",
        color8: "#0A7F69",
        // TODO: change
        color9: "#FFF8D9",
    },
    darkBlue: {
        color1: "#262687",
        color2: "#1D1D72",
        color3: "#13186B",
        color4: "#0A0A51",
        color5: "#1D1D72",
        color6: "#597F34",
        color7: "#597F34",
        color8: "#597F34",
        color9: "#5983CE",
    },
    lightBlue: {
        // TODO: change the whole set
        color1: "#88E2DE",
        color2: "#5BD6D3",
        color3: "#3CC3C6",
        color4: "#29B1B7",
        color5: "#3CC3C6",
        color6: "#098282",
        color7: "#036F72",
        color8: "#098282",
        color9: "#DDFFFC",
    },
    default: null
}

const warriorSelectorColors: { [key in ColorObj["name"]]: WarriorSelectorColors } & { default: null } = {
    turquoise: {
        bg: "#29B1B7",
        card: "#95E8E2",
    },
    yellow: {
        bg: "#F48E3D",
        card: "#F9D53E",
    },
    pink: {
        bg: "#AF2C7A",
        card: "#F487C8",
    },
    green: {
        bg: "#44A053",
        card: "#A1DD79",
    },
    darkBlue: {
        bg: "#06012D",
        card: "#82A6E8",
    },
    lightBlue: {
        bg: "#1996CE",
        card: "#66D7F2",
    },
    default: null
}

export const getTheme = (ourColor: ColorObj["name"], theirColor: ColorObj["name"], designPxSize: number) => ({
    ourColor,
    theirColor,
    warriorSelector: warriorSelectorColors[ourColor],
    leftCastle: castleColors[ourColor],
    rightCastle: castleColors[theirColor],
    ourWarriors: warriorsColors[ourColor],
    theirWarriors: warriorsColors[theirColor],
    designPxSize
})
