import styled, { css } from "styled-components"
import { adaptPx } from "utils/style-utils"

export const Avatar = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    width: ${adaptPx(80)};
    height: ${adaptPx(80)};
    border-radius: ${adaptPx(10)};
    pointer-events: none;
    background-color: ${(props) => props.theme.warriorSelector.card};
    overflow: hidden;

    .AvatarSVG {
        position: relative;
    }

    &.fairy .AvatarSVG {
        width: ${adaptPx(75)};
        height: ${adaptPx(86.41)};
        bottom: ${adaptPx(-16)};
    }
    &.dwarf .AvatarSVG {
        width: ${adaptPx(73)};
        height: ${adaptPx(81.69)};
        bottom: ${adaptPx(-11)};
    }
    &.knight .AvatarSVG {
        width: ${adaptPx(66)};
        height: ${adaptPx(88)};
        bottom: ${adaptPx(-9)};
    }
    &.skeleton .AvatarSVG {
        width: ${adaptPx(70)};
        height: ${adaptPx(109.12)};
        bottom: ${adaptPx(-6)};
    }
    &.wizard .AvatarSVG {
        width: ${adaptPx(66)};
        height: ${adaptPx(90.15)};
        bottom: ${adaptPx(-10)};
    }
`

export const WarriorStats = styled.span`
    position: absolute;
    width: ${adaptPx(80)};
    height: ${adaptPx(80)};
    background-color: white;
    border: ${adaptPx(4)} solid ${(props) => props.theme.warriorSelector.card};
    border-radius: ${adaptPx(10)};
    box-sizing: border-box;
    pointer-events: none;

    .icons {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        [fill="#AF2C7A"] {
            fill: ${(props) => props.theme.warriorSelector.bg};
        }

        [stroke="#AF2C7A"] {
            stroke: ${(props) => props.theme.warriorSelector.bg};
        }
    }
`

export const StatText = styled.div`
    height: 33%;
    color: #0a5c8a;
    font-weight: 600;
    font-size: ${adaptPx(16)};
    line-height: ${adaptPx(26)};
    padding-right: 15%;
    text-align: right;
`

export const WarriorCardWrap = styled.div<{ $isSelected?: boolean, $isDisabled: boolean, $price: number }>`
    position: relative;
    width: ${adaptPx(80)};
    height: ${adaptPx(80)};
    margin-left: ${adaptPx(20)};
    cursor: ${(props) => props.$isDisabled ? 'default' : 'pointer'};
    filter: ${(props) => props.$isDisabled ? 'grayscale(1)' : 'none'};

    &:after {
        position: absolute;
        top: ${adaptPx(-20)};
        width: 100%;
        text-align: center;
    }

    &:hover {
        ${WarriorStats} {
            z-index: 1;
        }
        &:after {
            content: ${(props) => `"${props.$price}  coins"`};
        }
    }

    ${(props) =>
        props.$isSelected
            ? css`
                  ${WarriorStats} {
                      z-index: 1;
                  }
              `
            : css`
                  &:hover {
                      ${WarriorStats} {
                          background-color: ${(props) => props.theme.warriorSelector.card};
                      }
                  }
              `}
`
