import styled, { css } from "styled-components"
import { adaptPx } from "utils/style-utils"
import heart from "../../images/castleHeart.svg"
import substrate from "../../images/healthSubstrate.svg"
import { COLOR_OBJECTS } from "shared/constants/colorObjects"

export const CastleWrap = styled.div<{ $side: "left" | "right" }>`
    width: ${adaptPx(151)};
    height: ${adaptPx(333)};
    position: absolute;
    bottom: 0;

    ${(props) =>
        props.$side === "right" &&
        css`
            right: 0;
        `}

    .castle-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        ${(props) =>
            props.$side === "left"
                ? css`
                      [fill="#F487C8"] {
                          fill: ${(props) => props.theme.leftCastle.color1};
                      }
                      [fill="#E85DB0"] {
                          fill: ${(props) => props.theme.leftCastle.color2};
                      }
                      [fill="#D33597"] {
                          fill: ${(props) => props.theme.leftCastle.color3};
                      }
                      [fill="#AF2C7A"] {
                          fill: ${(props) => props.theme.leftCastle.color4};
                      }
                      [fill="#CE4292"] {
                          fill: ${(props) => props.theme.leftCastle.color5};
                      }
                      [fill="#0B9E97"] {
                          fill: ${(props) => props.theme.leftCastle.color6};
                      }
                      [fill="#036F72"] {
                          fill: ${(props) => props.theme.leftCastle.color7};
                      }
                      [fill="#098282"] {
                          fill: ${(props) => props.theme.leftCastle.color8};
                      }
                  `
                : css`
                      transform: scale(-1, 1);
                      [fill="#F487C8"] {
                          fill: ${(props) => props.theme.rightCastle.color1};
                      }
                      [fill="#E85DB0"] {
                          fill: ${(props) => props.theme.rightCastle.color2};
                      }
                      [fill="#D33597"] {
                          fill: ${(props) => props.theme.rightCastle.color3};
                      }
                      [fill="#AF2C7A"] {
                          fill: ${(props) => props.theme.rightCastle.color4};
                      }
                      [fill="#CE4292"] {
                          fill: ${(props) => props.theme.rightCastle.color5};
                      }
                      [fill="#0B9E97"] {
                          fill: ${(props) => props.theme.rightCastle.color6};
                      }
                      [fill="#036F72"] {
                          fill: ${(props) => props.theme.rightCastle.color7};
                      }
                      [fill="#098282"] {
                          fill: ${(props) => props.theme.rightCastle.color8};
                      }
                  `}
    }
`

export const Shield = styled.div<{ $side: "left" | "right" }>`
    position: absolute;
    width: ${adaptPx(36)};
    height: ${adaptPx(47)};
    left: ${(props) => adaptPx(props.$side === "left" ? 80 : 6)};
    top: ${adaptPx(81)};
    background: url(${(props) => COLOR_OBJECTS.find((colorObj) => colorObj.name === (props.$side === "left" ? props.theme.ourColor : props.theme.theirColor)).imageURL}) center/contain no-repeat;
    ${(props) =>
        props.$side === "left" &&
        css`
            transform: scale(-1, 1);
        `}
`

export const HealthWrap = styled.div<{ $health: number; $side: "left" | "right" }>`
    height: ${(props) => props.$health}%;
    position: absolute;
    bottom: 0;
    left: 0;
    ${(props) =>
        props.$side === "right" &&
        css`
            left: auto;
            right: 0;
        `}
`

export const HealthMainWrap = styled.div<{ $side: "left" | "right" }>`
    height: ${adaptPx(145)};
    position: absolute;
    top: ${adaptPx(115)};
    ${(props) =>
        props.$side === "left"
            ? css`
                  left: ${adaptPx(25)};
              `
            : css`
                  left: auto;
                  right: ${adaptPx(25)};
              `}
`

export const Health = styled.div<{ $side: "left" | "right" }>`
    height: 100%;
    width: ${adaptPx(13)};
    background-color: ${(props) =>
        props.$side === "left" ? props.theme.leftCastle.color9 : props.theme.rightCastle.color9};
    border-radius: ${adaptPx(10)};
    opacity: 0.6;
`

export const Heart = styled.div`
    width: ${adaptPx(35)};
    height: ${adaptPx(32)};
    background: url(${heart}) center/contain no-repeat;
    top: ${adaptPx(-20)};
    position: absolute;
    left: ${adaptPx(-11)};
`

export const Substrate = styled.div<{ $side: "left" | "right" }>`
    width: ${adaptPx(53)};
    height: ${adaptPx(29)};
    background: url(${substrate}) center/contain no-repeat;
    color: #0a5c8a;
    text-align: center;
    font-weight: 600;
    font-size: ${adaptPx(20)};
    line-height: ${adaptPx(27)};
    position: absolute;
    bottom: ${adaptPx(25)};
    ${(props) =>
        props.$side === "left"
            ? css`
                  left: ${adaptPx(5)};
              `
            : css`
                  right: ${adaptPx(5)};
              `}
`
