import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import keyMirror from "utils/keymirror"
import type { WarriorTemplate } from "./types"

const FEATURE_NAME = "warriorsSelector"

const actionTypes = keyMirror(
    {
        SELECT_WARRIOR: null,
        UNSELECT_WARRIOR: null,
    },
    FEATURE_NAME
)

const initialState = {
    selectedWarrior: null as WarriorTemplate | null,
}

const selectWarrior = createAsyncThunk(
    actionTypes.SELECT_WARRIOR,
    (warrior: WarriorTemplate) => warrior
)

const unselectWarrior = createAsyncThunk(actionTypes.UNSELECT_WARRIOR, () => {})

const warriorsSelectorSlice = createSlice({
    name: FEATURE_NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(selectWarrior.fulfilled, (state, action) => {
                state.selectedWarrior = action.payload
            })
            .addCase(unselectWarrior.fulfilled, (state) => {
                state.selectedWarrior = null
            })
    },
    selectors: {
        selectSelectedWarrior: (state) => state.selectedWarrior,
    },
})

export const actions = { selectWarrior, unselectWarrior }
export const selectors = warriorsSelectorSlice.selectors
export default warriorsSelectorSlice.reducer
