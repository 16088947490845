import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import keyMirror from "utils/keymirror"

const FEATURE_NAME = "stars"

const actionTypes = keyMirror(
    {
        SET_STARS_AMOUNT: null,
        SET_STAR_TIMEOUT: null,
    },
    FEATURE_NAME
)

const initialState = {
    starsAmount: 3,
    starTimeout: 0,
}

const setStarsAmount = createAsyncThunk(
    actionTypes.SET_STARS_AMOUNT,
    (amount: number) => amount
)

const setStarTimeout = createAsyncThunk(
    actionTypes.SET_STAR_TIMEOUT,
    (timeout: number) => timeout
)

const starsSlice = createSlice({
    name: FEATURE_NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setStarsAmount.fulfilled, (state, action) => {
                state.starsAmount = action.payload
            })
            .addCase(setStarTimeout.fulfilled, (state, action) => {
                state.starTimeout = action.payload
            })
    },
    selectors: {
        selectStarsAmount: (state) => state.starsAmount,
        selectStarTimeout: (state) => state.starTimeout,
    },
})

export const actions = { setStarsAmount, setStarTimeout }
export const selectors = starsSlice.selectors
export default starsSlice.reducer
