import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { COLOR_OBJECTS } from "shared/constants/colorObjects"
import keyMirror from "utils/keymirror"
import { socket } from "utils/socket"
import type { ColorObj } from "./types"
const defaultColor = COLOR_OBJECTS[COLOR_OBJECTS.length - 1]

const FEATURE_NAME = "colorSelector"

const actionTypes = keyMirror(
    {
        SET_COLOR: null,
    },
    FEATURE_NAME
)

const initialState = {
    colorObj: defaultColor,
}

const setColor = createAsyncThunk(actionTypes.SET_COLOR, async (colorObj: ColorObj): Promise<ColorObj> => {
    return await new Promise((resolve, reject) => {
        socket.emitEventWithAcknolegement(
            "change-color",
            colorObj.name,
            (response: "ok" | "same-color") => {
                console.log("change-color response", response)
                switch (response) {
                    case "same-color":
                        reject()
                        break
                    case "ok":
                    default:
                        resolve(colorObj)
                }
            }
        )
    })
})

const colorSelectorSlice = createSlice({
    name: FEATURE_NAME,
    initialState,
    reducers: {
        resetColor: (state) => {
            state.colorObj = defaultColor
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setColor.fulfilled, (state, action) => {
            state.colorObj = action.payload
        })
        builder.addCase(setColor.rejected, () => {
            console.log("Tried to set the same color as opponent set before")
        })
    },
    selectors: {
        selectColorObj: (state) => state.colorObj,
        selectColorName: (state) => state.colorObj.name,
    },
})

export const actions = { ...colorSelectorSlice.actions, setColor }
export const selectors = colorSelectorSlice.selectors
export default colorSelectorSlice.reducer
