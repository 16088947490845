import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

const FEATURE_NAME = "socket"

// TODO: get from TG
const userId = `${Date.now().toString(36)}${Math.floor(36 + Math.random() * 1260).toString(36)}`

const initialState = {
    isInitialized: false,
    namespace: "/lobby",
    userId,
    userName: `user_${userId}`,
    isConnected: false,
    areListenersRegistered: false,
    isOpponentConnected: false,
}

const socketSlice = createSlice({
    name: FEATURE_NAME,
    initialState,
    reducers: {
        initializeSocket: (state) => {
            state.isInitialized = true
        },
        connectSocket: (state) => {
            state.isConnected = true
        },
        disconnectSocket: (state) => {
            state.isConnected = false
            state.areListenersRegistered = false
        },
        terminateSocket: (state) => {
            state.isInitialized = false
        },
        registerSocketListeners: (state, _action: PayloadAction<"lobby" | "game">) => {
            state.areListenersRegistered = true
        },
        setNamespace: (state, action: PayloadAction<string>) => {
            state.namespace = action.payload
        },
        setIsOpponentConnected: (state, action: PayloadAction<boolean>) => {
            state.isOpponentConnected = action.payload
        },
    },
    selectors: {
        selectIsSocketInitialized: (state) => state.isInitialized,
        selectIsSocketConnected: (state) => state.isConnected,
        selectAreSocketListenersRegisteres: (state) => state.areListenersRegistered,
        selectUserName: (state) => state.userName,
        selectIsOpponentConnected: (state) => state.isOpponentConnected,
    },
})

export const actions = socketSlice.actions
export const selectors = socketSlice.selectors
export default socketSlice.reducer
