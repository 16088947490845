import { isFulfilled } from "@reduxjs/toolkit"
import * as appDuck from "features/app/duck"
import * as socketDuck from "features/socket/duck"
import * as lobbyDuck from "features/lobby/duck"
import * as colorSelectorDuck from "features/colorSelector/duck"
import * as battlefieldDuck from "features/battlefield/duck"
import { socket } from "utils/socket"
import { COLOR_OBJECTS } from "shared/constants/colorObjects"
import type { OurWarrior, TheirWarrior, WarriorAtBattlefield } from "features/battlefield/duck/types"

const defaultColor = COLOR_OBJECTS[COLOR_OBJECTS.length - 1]

const isTogglePageFullfilled = isFulfilled(appDuck.actions.togglePage)

export const socketMiddleware = (store) => {
    return (next) => async (action) => {
        if (socketDuck.actions.initializeSocket.match(action)) {
            const { namespace, userId, userName } = store.getState().socket
            const { colorObj } = store.getState().colorSelector
            socket.create({
                namespace,
                userId,
                userName,
                colorName: colorObj.name,
            })

            socket.addListener("connect_error", () => {
                store.dispatch(appDuck.actions.togglePage("menu"))
            })
        }

        if (socketDuck.actions.connectSocket.match(action)) {
            socket.connect()
        }

        if (socketDuck.actions.disconnectSocket.match(action)) {
            socket.disconnect()
        }

        if (socketDuck.actions.terminateSocket.match(action)) {
            socket.terminate()
        }

        if (socketDuck.actions.registerSocketListeners.match(action)) {
            switch (action.payload) {
                case "lobby":
                    socket.addListener("opponent-not-found", () => {
                        console.log("opponent-not-found")
                    })

                    socket.addListener("opponent-found", (namespace, opponentUserName) => {
                        console.log("opponent-found namespace, opponentUserName", namespace, opponentUserName)
                        store.dispatch(socketDuck.actions.setNamespace(namespace))
                        store.dispatch(socketDuck.actions.setIsOpponentConnected(true))
                        store.dispatch(lobbyDuck.actions.addOpponent(opponentUserName))
                    })

                    socket.addListener("opponent-changed-color", (colorName) => {
                        console.log("opponent-changed-color", colorName)
                        store.dispatch(
                            lobbyDuck.actions.setOpponentColor(COLOR_OBJECTS.find((obj) => obj.name === colorName))
                        )
                    })

                    socket.addListener("opponent-ready", () => {
                        console.log("opponent-ready")
                        store.dispatch(lobbyDuck.actions.setOpponentReady())
                    })

                    socket.addListener("opponent-disconnected", () => {
                        console.log("opponent-disconnected")
                        store.dispatch(socketDuck.actions.setIsOpponentConnected(false))

                        const {
                            readyToPlay,
                            opponent: { isReady: isOpponentReady },
                        } = store.getState().lobby
                        if (!readyToPlay || !isOpponentReady) {
                            console.log("At least one of the players wasn't ready, so it wasn't move to play")
                            store.dispatch(lobbyDuck.actions.removeOpponent())
                            store.dispatch(socketDuck.actions.setIsOpponentConnected(false))
                            store.dispatch(lobbyDuck.actions.setReadyToPlay(false))
                            store.dispatch(colorSelectorDuck.actions.setColor(defaultColor))
                            store.dispatch(socketDuck.actions.setNamespace("/lobby"))
                        }
                    })

                    break

                case "game":
                    socket.addListener(
                        "opponent-connected",
                        (isAdvantage: boolean) => {
                            console.log("opponent-connected isAdvantage", isAdvantage)
                            store.dispatch(socketDuck.actions.setIsOpponentConnected(true))
                            store.dispatch(battlefieldDuck.actions.setAdvantage(isAdvantage))
                        }
                    )

                    socket.addListener("opponent-disconnected", () => {
                        console.log("opponent-disconnected")
                        store.dispatch(socketDuck.actions.setIsOpponentConnected(false))
                    })

                    socket.addListener(
                        "opponent-added-warrior",
                        (type: WarriorAtBattlefield["type"], key: WarriorAtBattlefield["key"]) => {
                            console.log("opponent-added-warrior", type, key)
                            store.dispatch(
                                battlefieldDuck.actions.addOpponentWarrior({
                                    type,
                                    key,
                                })
                            )
                        }
                    )

                    socket.addListener(
                        "opponent-moved-warrior",
                        (key: WarriorAtBattlefield["key"], position: WarriorAtBattlefield["position"], withStop: boolean) => {
                            console.log("move-opponent-warrior key, position, withStop", key, position, withStop)
                            store.dispatch(
                                battlefieldDuck.actions.moveOpponentWarrior({
                                    key,
                                    position,
                                    withStop,
                                })
                            )
                        }
                    )

                    socket.addListener(
                        "opponent-breaked-warrior-move",
                        (warriorKey: WarriorAtBattlefield["key"]) => {
                            console.log("opponent-breaked-warrior-move warriorKey", warriorKey)
                            store.dispatch(battlefieldDuck.actions.breakOpponentWarriorMove(warriorKey))
                        }
                    )

                    socket.addListener(
                        "opponent-started-battle",
                        (warriorKey: WarriorAtBattlefield["key"]) => {
                            console.log("opponent-started-battle warriorKey", warriorKey)
                            store.dispatch(battlefieldDuck.actions.startOpponentBattle(warriorKey))
                        }
                    )

                    socket.addListener(
                        "opponent-attacked-warrior",
                        (
                            key: TheirWarrior["key"],
                            enemyKey: OurWarrior["key"],
                            amount: number
                        ) => {
                            console.log("opponent-attacked-warrior key, enemyKey, amount", key, enemyKey, amount)
                            store.dispatch(
                                battlefieldDuck.actions.opponentAttack({
                                    key,
                                    enemyKey,
                                    amount,
                                })
                            )
                        }
                    )

                    socket.addListener(
                        "opponent-finished-battle",
                        (warriorKey: WarriorAtBattlefield["key"]) => {
                            console.log("opponent-finished-battle warriorKey", warriorKey)
                            store.dispatch(battlefieldDuck.actions.finishOpponentBattle(warriorKey))
                        }
                    )

                    socket.addListener(
                        "opponent-dropped-warrior-life",
                        (warriorKey: WarriorAtBattlefield["key"], healthLeft: number) => {
                            console.log("opponent-droped-warrior-life warriorKey, healthLeft", warriorKey, healthLeft)
                            store.dispatch(
                                battlefieldDuck.actions.dropOpponentWarriorLife({
                                    warriorKey,
                                    amount: healthLeft,
                                })
                            )
                        }
                    )

                    socket.addListener(
                        "opponent-attacked-tower",
                        (key: TheirWarrior["key"], amount: number) => {
                            console.log("opponent-attacked-tower amount key, amount", key, amount)
                            store.dispatch(battlefieldDuck.actions.opponentAttack({ key, enemyKey: "tower", amount}))
                        }
                    )

                    socket.addListener(
                        "opponent-dropped-tower-life",
                        (healthLeft: number) => {
                            console.log("opponent-dropped-tower-life healthLeft", healthLeft)
                            store.dispatch(battlefieldDuck.actions.dropOpponentTowerLife(healthLeft))
                        }
                    )

                    break
                default:
                    break
            }
        }

        if (isTogglePageFullfilled(action)) {
            if (store.getState().app.currentPage === "preGame" && action.meta.arg === "menu") {
                store.dispatch(socketDuck.actions.disconnectSocket())
                store.dispatch(lobbyDuck.actions.removeOpponent())
                store.dispatch(socketDuck.actions.terminateSocket())
                store.dispatch(colorSelectorDuck.actions.resetColor())
            }

            if (store.getState().app.currentPage === "game" && action.meta.arg === "menu") {
                store.dispatch(socketDuck.actions.disconnectSocket())
                store.dispatch(socketDuck.actions.terminateSocket())
                store.dispatch(lobbyDuck.actions.removeOpponent())
                store.dispatch(lobbyDuck.actions.setReadyToPlay(false))
                store.dispatch(socketDuck.actions.setNamespace("/lobby"))
                store.dispatch(colorSelectorDuck.actions.resetColor())
            }
        }

        next(action)
    }
}
