import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Battlefield } from "features/battlefield"
import { WarriorsSelector } from "features/warriorsSelector"
import { Purchase } from "features/purchase"
import { GameOver } from "features/shared/gameOver"
import { Header } from "features/shared/header"
import * as socketDuck from "features/socket/duck"
import * as battlefieldDuck from "features/battlefield/duck"
import { GamePageWrap } from "./elements"

export const GamePage = () => {
    const dispatch = useDispatch()
    const isSocketInitialized = useSelector(socketDuck.selectors.selectIsSocketInitialized)
    const isSocketConnected = useSelector(socketDuck.selectors.selectIsSocketConnected)
    const areSocketListenersRegistered = useSelector(socketDuck.selectors.selectAreSocketListenersRegisteres)
    const isGameOver = useSelector(battlefieldDuck.selectors.selectIsGameOver)

    useEffect(() => {
        if (!isSocketInitialized) {
            dispatch(socketDuck.actions.initializeSocket())
        } else if (!isSocketConnected) {
            dispatch(socketDuck.actions.connectSocket())
        } else if (!areSocketListenersRegistered) {
            dispatch(socketDuck.actions.registerSocketListeners("game"))
        }
    }, [dispatch, isSocketInitialized, isSocketConnected, areSocketListenersRegistered])

    return (
        <GamePageWrap>
            <Header pageId="menu" />
            <Battlefield />
            <WarriorsSelector />
            <Purchase />
            {isGameOver && <GameOver />}
        </GamePageWrap>
    )
}
