import { useSelector } from "react-redux"
import * as duck from "./duck"
import { StarsWrap, StarWrap, Star, StarPlace, Hint, Text, Separator, HintStar, Timer } from "./elements"

export const Stars = () => {
    const amount = useSelector(duck.selectors.selectStarsAmount)
    const timeout = useSelector(duck.selectors.selectStarTimeout)

    return (
        <StarsWrap>
            {[3, 2, 1].map((i) => (
                <StarWrap key={i}>
                    <StarPlace />
                    {amount >= i ? <Star /> : null}
                </StarWrap>
            ))}
            <Hint className="hint">
                <Text>
                    Игра стоит одну <HintStar />
                </Text>
                <Separator />
                <Text>
                    Чтобы восстановить <HintStar />
                    <br /> подожди
                    <br />
                    {/* TODO: уточнить */}
                    <Timer>{timeout}</Timer>
                </Text>
            </Hint>
        </StarsWrap>
    )
}
