import styled from "styled-components";
import { adaptPx } from "utils/style-utils";
import cloud from "shared/images/cloud.svg"

export const GameOverWrap = styled.div`
    position: absolute;
    height: ${adaptPx(350)};
    width: ${adaptPx(795)};
    top:  ${adaptPx(-1)};
    left: 50%;
    transform: translateX(-50%);
    background: url(${cloud}) center/contain no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: ${adaptPx(40)} ${adaptPx(100)} ${adaptPx(100)};
`

export const Result = styled.div`
    text-align: center;
    font-size: ${adaptPx(16)};
`

export const ButtonWrap = styled.div`
    text-align: center;

    .returnButton {
        padding: ${adaptPx(18)} 0;
        width: ${adaptPx(172)};
        height: ${adaptPx(12)};
        line-height: ${adaptPx(12)};
        border-radius: ${adaptPx(6)};
    }
`
