import styled from "styled-components"
import { adaptPx } from "utils/style-utils"

export const MenuWrap = styled.div`
    padding: ${adaptPx(30)} ${adaptPx(30)} ${adaptPx(20)} ${adaptPx(30)};
    background: #00508a;
    border-radius: ${adaptPx(20)};
    align-items: center;
    display: flex;
    flex-direction: column;
`

export const ButtonWrap = styled.div`
    margin-bottom: ${adaptPx(15)};
`
